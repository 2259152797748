import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Datetime from 'react-datetime';
import moment from 'moment';
import { CampaignApi } from '../../services/CampaignApi';
import { SelectedAdAccountIdContext } from '../../context/SelectedAdAccount';

interface IProps {
  campaigns: any;
  selectedCampaign: number;
  showModal: boolean;
  handleClose: () => void;
  onShowModalHandle: (isShow: boolean) => () => void;
  UpdateValues: () => void;
}

const EditCampaign = ({
  campaigns,
  selectedCampaign,
  showModal,
  handleClose,
  onShowModalHandle,
  UpdateValues,
}: IProps) => {
  const { selectedAdAccountId } = useContext(SelectedAdAccountIdContext);

  const day = 60 * 60 * 24 * 1000;
  const [inputDate, setInputDate] = useState({
    start: moment(),
    end: moment(),
  });
  const [inputTime, setInputTime] = useState({
    start: moment(),
    end: moment(),
  });

  const [selectedDays, setSelectedDays] = useState<number[]>([]);
  const [devices, setDevices] = useState(['android', 'linux', 'mac', 'windows']);

  useEffect(() => {
    setInputDate({
      start: moment(campaigns[selectedCampaign].runSchedule.start),
      end: moment(campaigns[selectedCampaign].runSchedule.end),
    });
    setInputTime({
      start: moment(campaigns[selectedCampaign].startTime),
      end: moment(campaigns[selectedCampaign].endTime),
    });

    setSelectedDays(campaigns[selectedCampaign].daysOfWeek || []);
  }, [campaigns, selectedCampaign]);

  const onResetSettings = () => {
    setSelectedDays([]);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    const toastId = toast.loading('Updating campaign!');
    const data = {
      runSchedule: {
        start: inputDate.start.valueOf(),
        end: inputDate.end.valueOf(),
      },
      startTime: inputTime.start.valueOf(),
      endTime: inputTime.end.valueOf(),
      selectedDays: selectedDays,
      devices: devices,
      isActive: campaigns.status === 'ACTIVE',
      id: campaigns[selectedCampaign].id,
    };

    CampaignApi.create({
      id: selectedAdAccountId || '',
      data,
    })
      .then(res => {
        UpdateValues();
        handleClose();
        toast.update(toastId, {
          render: 'Success',
          type: 'success',
          isLoading: false,
          autoClose: 2000,
        });
      })
      .catch(rej => {
        toast.update(toastId, {
          render: 'Error',
          type: 'error',
          isLoading: false,
          autoClose: 2000,
        });
      })
      .finally(() => {
        onShowModalHandle(false)();
      });
  };

  return (
    <Modal show={showModal} onHide={onShowModalHandle(false)}>
      <Form
        style={{
          maxHeight: '90vh',
          overflow: 'auto',
        }}
        method='POST'
        onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit campaign</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <>
              <Row>
                <Form.Group className='mb-3' as={Col}>
                  <Form.Label>Start Date</Form.Label>
                  <Datetime
                    isValidDate={current => current.isAfter(moment())}
                    inputProps={{
                      disabled: inputDate.start.isBefore(moment()),
                    }}
                    timeFormat={false}
                    value={inputDate.start}
                    onChange={date =>
                      setInputDate({
                        ...inputDate,
                        start: typeof date === 'string' ? moment() : date,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group className='mb-3' as={Col}>
                  <Form.Label>End Date</Form.Label>
                  {campaigns[selectedCampaign].runSchedule.end && (
                    <Datetime
                      isValidDate={current => current.isAfter(moment())}
                      inputProps={{
                        disabled:
                          inputDate.start.isBefore(moment()) ||
                          inputDate.end.isSame(moment(), 'days'),
                      }}
                      timeFormat={false}
                      value={inputDate.end}
                      onChange={date =>
                        setInputDate({
                          ...inputDate,
                          end: typeof date === 'string' ? moment() : date,
                        })
                      }
                    />
                  )}
                  {selectedCampaign !== null &&
                  (inputDate.start.isBefore(moment()) || inputDate.end.isSame(moment(), 'days')) ? (
                    <p>
                      Click{' '}
                      <small>
                        <a
                          href={`https://www.linkedin.com/campaignmanager/accounts/${selectedAdAccountId}/campaigns/${campaigns[selectedCampaign].id}/details`}
                          target='__blank'>
                          here
                        </a>
                      </small>{' '}
                      to edit end date
                    </p>
                  ) : null}
                </Form.Group>
                <Form.Group>
                  {inputDate.start.isBefore(moment()) && (
                    <div className='alert alert-danger' role='alert'>
                      Campaign has already started
                    </div>
                  )}
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className='mb-3' as={Col}>
                  <Form.Label>Start Time</Form.Label>
                  <Datetime
                    dateFormat={false}
                    value={inputTime.start}
                    onChange={date =>
                      setInputTime({
                        ...inputTime,
                        start: typeof date === 'string' ? moment() : date,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group className='mb-3' as={Col}>
                  <Form.Label>End Time</Form.Label>
                  <Datetime
                    dateFormat={false}
                    value={inputTime.end}
                    onChange={date =>
                      setInputTime({
                        ...inputTime,
                        end: typeof date === 'string' ? moment() : date,
                      })
                    }
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className='mb-3' as={Col}>
                  <Form.Label>Days</Form.Label>
                  <div className='mb-3'>
                    {[
                      { index: 0, label: 'Sunday' },
                      { index: 1, label: 'Monday' },
                      { index: 2, label: 'Tuesday' },
                      { index: 3, label: 'Wednesday' },
                      { index: 4, label: 'Thursday' },
                      { index: 5, label: 'Friday' },
                      { index: 6, label: 'Saturday' },
                    ].map(item => (
                      <Form.Check
                        key={`selectDay_${item.label}`}
                        inline
                        label={item.label}
                        type='checkbox'
                        checked={selectedDays.includes(item.index)}
                        onChange={e => {
                          if (selectedDays.includes(item.index)) {
                            setSelectedDays(
                              selectedDays.filter((val: number) => val !== item.index)
                            );
                          } else {
                            setSelectedDays([...selectedDays, item.index]);
                          }
                        }}
                      />
                    ))}
                  </div>
                </Form.Group>
              </Row>
              {/* <Row>
                    <Form.Group className="mb-3" as={Col}>
                      <Form.Label>Devices</Form.Label>
                      <div className="mb-3">
                        {[
                          { index: "android", label: "Android" },
                          { index: "linux", label: "Linux" },
                          { index: "mac", label: "Mac" },
                          { index: "windows", label: "Windows" },
                        ].map((item) => (
                          <Form.Check
                            inline
                            label={item.label}
                            type="checkbox"
                            checked={devices.includes(item.index)}
                            onClick={(e) => {
                              if (devices.includes(item.index)) {
                                setdevices(
                                  devices.filter((val) => val !== item.index)
                                );
                              } else {
                                setdevices([...devices, item.index]);
                              }
                            }}
                          />
                        ))}
                      </div>
                    </Form.Group>
                  </Row> */}
            </>
            {/* )} */}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-light' style={{ marginRight: 'auto' }} onClick={onResetSettings}>
            <span style={{ color: 'red' }}>Reset</span>
          </Button>
          <Button variant='secondary' onClick={onShowModalHandle(false)}>
            Close
          </Button>
          <Button variant='primary' type='submit'>
            Submit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditCampaign;
