import {configureStore} from '@reduxjs/toolkit';

import rootReducer from './reducers';
import thunkMiddlewear from 'redux-thunk';

// const store = createStore(rootReducer, applyMiddleware(thunkMiddlewear));

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(thunkMiddlewear),
});
export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
