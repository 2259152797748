export const headers = () => {
  const token = localStorage.getItem('linkedin-access-code');
  return {'linkedin-access-code': token || ''};
};

export const headersForHubspot = () => {
  const token = localStorage.getItem('hubspot-access-code');
  return {'hubspot-access-code': token || ''};
};

export const headersForLogin = () => {
  const token = localStorage.getItem('access_token');
  return {Authorization: token || ''};
};
