import React, { FC, useEffect } from 'react';
import {
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import styles from './styles.module.scss';
import { setRegisterError, userRegister } from '../../redux/slices/auth';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { NumericFormat } from 'react-number-format';

type SucessRegister = {
  status: string;
  message: string;
};
const MIN_PASSWORD_LENGTH = 6;

const SignupSchema = Yup.object().shape({
  login: Yup.string().min(MIN_PASSWORD_LENGTH, 'Too Short!').max(50, 'Too Long!'),
  password: Yup.string()
    .min(MIN_PASSWORD_LENGTH, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  confirmPassword: Yup.string()
    .min(MIN_PASSWORD_LENGTH, 'Too Short!')
    .max(50, 'Too Long!')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

export const RegisterForm: FC = () => {
  const dispatch = useAppDispatch();
  const { authPending, registerError } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    return function () {
      if (registerError) dispatch(setRegisterError(null));
    };
  }, [dispatch, registerError]);

  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
      confirmPassword: '',
      email: '',
      agency_or_brand: '',
      estimated_monthly_ad_spend: '',
    },
    validationSchema: SignupSchema,
    onSubmit: async values => {
      const { login, password, email, agency_or_brand, estimated_monthly_ad_spend } = values;
      const estimated_monthly_spend: number = parseInt(
        estimated_monthly_ad_spend.replace(/[\$,]/g, '')
      );

      const data = {
        username: email,
        estimated_monthly_ad_spend: estimated_monthly_spend,
        agency_or_brand,
        infousername: login,
        password: password,
      };
      const responce: SucessRegister = await dispatch(userRegister(data));
      if (responce.status === 'Success') Swal.fire(responce.message, '', 'success');
    },
  });

  const { handleSubmit, values, handleChange, errors, touched } = formik;

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Typography variant='h1' className={styles.greeting}>
          Welcome!
        </Typography>
        <Typography variant='h2' className={styles.subGreeting}>
          Create your account
        </Typography>
        <Fade in={!!registerError}>
          <Typography color='error' className={styles.errorMessage}>
            {registerError || 'Something went wrong :('}
          </Typography>
        </Fade>
        <TextField
          id='login'
          name='login'
          variant='standard'
          InputProps={{
            classes: {
              underline: styles.textFieldUnderline,
            },
          }}
          value={values.login}
          onChange={handleChange}
          margin='normal'
          label='Name'
          type='text'
          fullWidth
          helperText={errors.login && touched.login ? errors.login : null}
          error={errors && errors.login && touched && touched.login ? true : false}
        />
        <TextField
          id='email'
          name='email'
          variant='standard'
          InputProps={{
            classes: {
              underline: styles.textFieldUnderline,
            },
          }}
          value={values.email}
          onChange={handleChange}
          margin='normal'
          placeholder='Email'
          type='text'
          label='Email'
          fullWidth
          required
          helperText={errors.email && touched.email ? errors.email : null}
          error={errors && errors.email && touched && touched.email ? true : false}
        />
        <FormControl variant='standard' sx={{ minWidth: 120 }} fullWidth margin='normal'>
          <InputLabel id='demo-simple-select-standard-label'>Agency or brand</InputLabel>
          <Select
            labelId='demo-simple-select-standard-label'
            id='demo-simple-select-standard'
            name='agency_or_brand'
            value={values.agency_or_brand}
            onChange={handleChange}
            classes={{
              standard: styles.textFieldUnderline,
            }}
            label='Agency or brand'>
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value='agency'>Agency</MenuItem>
            <MenuItem value='brand'>Brand</MenuItem>
          </Select>
        </FormControl>
        <NumericFormat
          thousandSeparator=','
          allowNegative={false}
          prefix={'$'}
          customInput={TextField}
          id='estimated_monthly_ad_spend'
          name='estimated_monthly_ad_spend'
          variant='standard'
          InputProps={{
            classes: {
              underline: styles.textFieldUnderline,
            },
          }}
          value={values.estimated_monthly_ad_spend}
          onChange={handleChange}
          margin='normal'
          label='Estimated monthly ad spend'
          fullWidth
        />

        <TextField
          id='password'
          name='password'
          variant='standard'
          InputProps={{
            classes: {
              underline: styles.textFieldUnderline,
            },
          }}
          value={values.password}
          onChange={handleChange}
          margin='normal'
          label='Password'
          type='password'
          fullWidth
          required
          helperText={errors.password && touched.password ? errors.password : null}
          error={errors && errors.password && touched && touched.password ? true : false}
        />
        <TextField
          id='confirmPassword'
          name='confirmPassword'
          variant='standard'
          InputProps={{
            classes: {
              underline: styles.textFieldUnderline,
            },
          }}
          value={values.confirmPassword}
          onChange={handleChange}
          margin='normal'
          label='Confirm password'
          type='password'
          fullWidth
          required
          helperText={
            errors.confirmPassword && touched.confirmPassword ? errors.confirmPassword : null
          }
          error={
            errors && errors.confirmPassword && touched && touched.confirmPassword ? true : false
          }
        />
        <div className={styles.creatingButtonContainer}>
          {authPending ? (
            <CircularProgress size={26} />
          ) : (
            <Button
              disabled={values.login.length === 0 || values.password.length === 0}
              size='large'
              variant='contained'
              color='primary'
              fullWidth
              className={styles.createAccountButton}
              type='submit'>
              Create your account
            </Button>
          )}
        </div>
      </form>
    </React.Fragment>
  );
};

export default RegisterForm;
