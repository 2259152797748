import React, {Dispatch} from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment, {Moment} from 'moment';
import {IDateRangeState} from '../pages/Leads';

interface Props {
  dateRange: {start: any; end: any};
  setDateRange: Dispatch<IDateRangeState>;
}

const DateRangePickerComponent: React.FC<Props> = ({dateRange, setDateRange}) => {
  const handleCallback = (start: Moment, end: Moment) => {
    setDateRange({start, end});
  };
  return (
    <DateRangePicker
      initialSettings={{
        startDate: dateRange.start,
        endDate: dateRange.end,
        showDropdowns: true,
        locale: {
          format: 'DD.MM.YYYY',
        },
        alwaysShowCalendars: true,
        ranges: {
          'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
          'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
          'This Month': [moment().startOf('month').toDate(), moment().toDate()],
          'Last Month': [
            moment().subtract(1, 'month').startOf('month').toDate(),
            moment().subtract(1, 'month').endOf('month').toDate(),
          ],
          'This quarter': [moment().startOf('Q').toDate(), moment().toDate()],
          'This Year': [moment().startOf('y').toDate(), moment().toDate()],
        },
      }}
      onCallback={handleCallback}
    >
      <input type='text' className='form-control' />
    </DateRangePicker>
  );
};
export default DateRangePickerComponent;
