import React, { useEffect, useMemo } from 'react';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../hooks/reduxHooks';
import CrmSettings from './CrmSettings';
import SubscriptionPlans from './SubscriptionPlans';
import UsersSettings from './UsersSettings';

type SettingsTabKey = 'crm' | 'users' | 'subscription';

const tabKeys: SettingsTabKey[] = ['crm', 'users', 'subscription'];

interface Props {}

const Settings = (props: Props) => {
  const history = useHistory();
  const { meInfo } = useAppSelector(state => state.auth);

  const pageBeforeRedirect = localStorage.getItem('page-before-redirect');

  useEffect(() => {
    if (pageBeforeRedirect) {
      history.replace(pageBeforeRedirect, history.location.pathname);
      localStorage.removeItem('page-before-redirect');
    }
  }, [pageBeforeRedirect]);

  const activeTabKey: SettingsTabKey | null = useMemo(() => {
    return tabKeys.find(key => history.location.pathname.includes(key)) || null;
  }, [history.location]);

  const onSelectTabHandler = (select: string | null) => {
    const selectWithoutNull = select || 'crm';
    const path = activeTabKey
      ? history.location.pathname.replace(activeTabKey, selectWithoutNull)
      : `${history.location.pathname}/${selectWithoutNull}`;

    history.push(path);
  };

  return (
    <div className='pageContent settings'>
      <div className='border-bottom px-3 bg-light pageContent__title'>
        <p>Settings</p>
      </div>
      <div className='p-4'>
        <Tabs
          id='controlled-tab-example'
          activeKey={activeTabKey || 'crm'}
          onSelect={onSelectTabHandler}
          className='mb-3'>
          <Tab eventKey='crm' title='CRM'>
            {meInfo && <CrmSettings meInfo={meInfo} />}
          </Tab>
          <Tab
            eventKey='users'
            title={meInfo && meInfo.role === 'admin' ? 'Users' : 'Account information'}>
            {meInfo && activeTabKey === 'users' && <UsersSettings meInfo={meInfo} />}
          </Tab>
          <Tab eventKey='subscription' title='Subscription plans'>
            {meInfo && activeTabKey === 'subscription' && <SubscriptionPlans meInfo={meInfo} />}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Settings;
