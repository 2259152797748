import React, { FC, useEffect } from 'react';
import { Table, Form } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { getAllPlans } from '../../../redux/slices/payment';

const initialFormikValues = (plans: any[]) => {
  if (plans && plans.length > 0) {
    return {
      subscriptions:
        plans &&
        plans.map(item => ({
          id: item.plans[0].product,
          name: item.name,
          plan: item.plans[0].interval.charAt(0).toUpperCase() + item.plans[0].interval.slice(1),
          price: item.plans[0].amount,
          status: item.active ? 'Active' : 'Not active',
          editMode: false,
        })),
    };
  } else return { subscriptions: [] };
};
interface Props {
  arrayHelperRef: any;
}

const SubscriptionsTable: FC<Props> = ({ arrayHelperRef }) => {
  const { plans } = useAppSelector(state => state.payment);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllPlans());
  }, [dispatch]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormikValues(plans),
    onSubmit: values => {
      // alert(JSON.stringify(values, null, 2));
    },
  });

  const { values, handleChange, setFieldValue } = formik;

  const editHandler = (index: number) => {
    const isEditModa = values.subscriptions[index].editMode;
    setFieldValue(`subscriptions[${index}].editMode`, !isEditModa);
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Tariff plan</th>
          <th>Tariff price</th>
          {/* <th>Actions</th> */}
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <FormikProvider value={formik}>
          <FieldArray
            name='subscriptions'
            render={arrayHelpers => {
              arrayHelperRef.current = arrayHelpers;
              return (
                <>
                  {values.subscriptions.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <p className='m-0 py-2'>{item.id}</p>
                        </td>
                        <td>
                          {!values.subscriptions[index].editMode ? (
                            <p className='m-0 py-2'>{values.subscriptions[index].name}</p>
                          ) : (
                            <Form.Select
                              name={`subscriptions[${index}].name`}
                              value={values.subscriptions[index].name}
                              onChange={handleChange}>
                              <option>Standart tariff</option>
                              <option>Premium tariff</option>
                            </Form.Select>
                          )}
                        </td>
                        <td>
                          {!values.subscriptions[index].editMode ? (
                            <p className='m-0 py-2'>{values.subscriptions[index].plan}</p>
                          ) : (
                            <Form.Select
                              name={`subscriptions[${index}].plan`}
                              value={values.subscriptions[index].plan}
                              onChange={handleChange}>
                              <option>Weekly</option>
                              <option>Monthly</option>
                              <option>Yearly</option>
                            </Form.Select>
                          )}
                        </td>
                        <td>
                          {!values.subscriptions[index].editMode ? (
                            <p className='m-0 py-2'>{values.subscriptions[index].price}</p>
                          ) : (
                            <Form.Control
                              type='text'
                              name={`subscriptions[${index}].price`}
                              value={values.subscriptions[index].price}
                              onChange={handleChange}
                            />
                          )}
                        </td>
                        {/* <td>
                          <div className='h-100 d-flex justify-content-start align-items-center'>
                            {values.subscriptions[index].editMode ? (
                              <button
                                className='p-2 border-0 action-button'
                                onClick={() => editHandler(index)}>
                                <FontAwesomeIcon
                                  icon={solid('floppy-disk')}
                                  size='lg'
                                  className='actionButton'
                                />
                              </button>
                            ) : (
                              <button
                                className='p-2 border-0 action-button'
                                onClick={() => editHandler(index)}>
                                <FontAwesomeIcon
                                  icon={solid('pen-to-square')}
                                  size='lg'
                                  className='actionButton'
                                />
                              </button>
                            )}

                            <button
                              className='p-2 border-0 action-button'
                              onClick={() => arrayHelpers.remove(index)}>
                              <FontAwesomeIcon
                                icon={solid('trash')}
                                size='lg'
                                className='actionButton'
                              />
                            </button>
                          </div>
                        </td> */}
                        <td>
                          {!values.subscriptions[index].editMode ? (
                            <p className='m-0 py-2'>{values.subscriptions[index].status}</p>
                          ) : (
                            <Form.Select
                              name={`subscriptions[${index}].status`}
                              value={values.subscriptions[index].status}
                              onChange={handleChange}>
                              <option value='Active'>Active</option>
                              <option value='Not active'>Not active</option>
                            </Form.Select>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </>
              );
            }}
          />
        </FormikProvider>
      </tbody>
    </Table>
  );
};

export default SubscriptionsTable;
