import React, { FC, useEffect, useRef, useState } from 'react';
import { Table, Button, Form, Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { sortData } from '../../../utils/serachAndFilterCustomers';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { getAllUsers, updateUserInfo } from '../../../redux/slices/users';

import { IMeInfo, updateMyInfo } from '../../../redux/slices/auth';
import PaymentModal from './PaymentModal';

interface IProps {
  meInfo: IMeInfo;
}

interface IUser {
  username: string;
  estimated_monthly_ad_spend: number;
  agency_or_brand: string;
  infousername: string;
  role?: string;
  status?: string;
}

export interface ISortBy {
  name: string;
  sortParam: string;
}

const initialFormikValues = (me: IMeInfo, usersList: any[] | null) => {
  const usersInitial = {
    users: [
      {
        id: me._id,
        name: me.infousername,
        monthlySpend: me.estimated_monthly_ad_spend,
        type: me.agency_or_brand,
        email: me.username,
        role: me.role,
        subscription: me.subscription.subscription_name || '-',
        status: me.status,
        registrationDate: me.dateCreated ? new Date(me.dateCreated) : null,
        editMode: false,
        subscriptionEndDate: me.subscription.end_date
          ? `till ${moment.unix(me.subscription.end_date).format('DD/MM/YYYY')}`
          : '',
      },
    ],
  };

  if (usersList && usersList.length > 0) {
    const newUser = usersList.filter(item => item._id !== me._id);
    const newUserFormat = newUser.map(item => ({
      id: item._id,
      name: item.infousername,
      monthlySpend: item.estimated_monthly_ad_spend,
      type: item.agency_or_brand,
      email: item.username,
      role: item.role,
      subscription: item.subscription.subscription_name || '-',
      status: item.status,
      registrationDate: item.dateCreated ? new Date(item.dateCreated) : null,
      editMode: false,
      subscriptionEndDate: item.subscription.end_date
        ? `till ${moment.unix(item.subscription.end_date).format('DD/MM/YYYY')}`
        : '',
    }));
    usersInitial.users = [...usersInitial.users, ...newUserFormat];
  }

  return usersInitial;
};
const UsersSettings: FC<IProps> = ({ meInfo }) => {
  const [sortBy, setSortBy] = useState<ISortBy | null>(null);
  const { usersList } = useAppSelector(state => state.users);
  const [isShowPaymentModal, setIsShowPaymentModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const arrayHelperRef = useRef<any>();
  const dispatch = useAppDispatch();

  const isAdmin = meInfo.role === 'admin';

  useEffect(() => {
    if (isAdmin) {
      dispatch(getAllUsers());
    }
  }, [dispatch, isAdmin]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormikValues(meInfo, usersList),
    onSubmit: values => {},
  });

  const { handleSubmit, values, handleChange, setFieldValue } = formik;

  const addItemHandler = () => {
    arrayHelperRef.current.push({
      name: '',
      monthlySpend: '',
      type: '',
      email: '',
      role: '',
      subscription: '',
      status: '',
      registrationDate: null,
      editMode: false,
    });
  };

  const editHandler = (index: number) => {
    const isEditModa = values.users[index].editMode;

    if (isEditModa) {
      const { id, name, monthlySpend, type, email, role, status } = values.users[index];

      let userForRequest: IUser = {
        username: email,
        estimated_monthly_ad_spend: monthlySpend,
        agency_or_brand: type,
        infousername: name,
      };

      if (isAdmin) {
        userForRequest = {
          ...userForRequest,
          role: role,
          // status,
        };
      }

      if (id === meInfo._id) {
        dispatch(updateMyInfo(id, userForRequest));
      } else {
        dispatch(updateUserInfo(id, userForRequest));
      }
    }
    setFieldValue(`users[${index}].editMode`, !isEditModa);
  };

  const handlerSort = (value: string) => {
    const sortQuery = {
      name: value,
      sortParam: sortBy && sortBy.sortParam === 'asc' ? 'dec' : 'asc',
    };
    setSortBy(sortQuery);
    sortData(values.users, sortQuery);
  };

  const sortFunc = (name: string) => {
    return sortBy && sortBy.name === name ? (
      sortBy.sortParam === 'asc' ? (
        <FontAwesomeIcon icon={solid('sort-up')} size='sm' />
      ) : (
        <FontAwesomeIcon icon={solid('sort-down')} size='sm' />
      )
    ) : (
      ''
    );
  };

  const onPaymentOpenHandler = () => {
    setIsShowPaymentModal(prevState => !prevState);
  };

  return (
    <div className='userSettings'>
      {/* {isAdmin && (
        <div className='py-4'>
          <Form>
            <Row>
              <Col xs={6} sm={4} xxl={3}>
                <Button className='w-100 mt-2' variant='success' onClick={addItemHandler}>
                  <i className='bi bi-plus-lg me-1'></i> Add new user
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )} */}
      <div className='py-4'>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th onClick={() => handlerSort('name')}>User name {sortFunc('name')}</th>
              <th onClick={() => handlerSort('monthlySpend')}>
                Monthly Ad spend {sortFunc('monthlySpend')}
              </th>
              <th onClick={() => handlerSort('type')}>Agency/Brand {sortFunc('type')}</th>
              <th onClick={() => handlerSort('email')}>E-mail {sortFunc('email')}</th>
              <th onClick={() => handlerSort('role')}>Role {sortFunc('role')}</th>
              <th onClick={() => handlerSort('subscription')}>
                Subscription plan {sortFunc('subscription')}
              </th>
              <th onClick={() => handlerSort('status')}>
                Subscription status {sortFunc('status')}
              </th>
              <th onClick={() => handlerSort('registrationDate')}>
                Registration Date {sortFunc('registrationDate')}
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <FormikProvider value={formik}>
              <FieldArray
                name='users'
                render={arrayHelpers => {
                  arrayHelperRef.current = arrayHelpers;
                  return (
                    <>
                      {values.users.map((item, index) => {
                        const isMeThisMeInfo = values.users[index].id === meInfo._id;
                        return (
                          <tr key={item.id}>
                            <td>
                              <p className='m-0 py-2'>{item.id}</p>
                            </td>
                            <td>
                              {!values.users[index].editMode ? (
                                <p className='m-0 py-2'>{values.users[index].name}</p>
                              ) : (
                                <Form.Control
                                  type='text'
                                  name={`users[${index}].name`}
                                  value={values.users[index].name}
                                  onChange={handleChange}
                                />
                              )}
                            </td>
                            <td>
                              {!values.users[index].editMode ? (
                                <p className='m-0 py-2'>{values.users[index].monthlySpend}</p>
                              ) : (
                                <Form.Control
                                  type='number'
                                  name={`users[${index}].monthlySpend`}
                                  value={values.users[index].monthlySpend}
                                  onChange={handleChange}
                                />
                              )}
                            </td>
                            <td>
                              {!values.users[index].editMode ? (
                                <p className='m-0 py-2'>{values.users[index].type}</p>
                              ) : (
                                <Form.Select
                                  name={`users[${index}].type`}
                                  value={values.users[index].type}
                                  onChange={handleChange}>
                                  <option></option>
                                  <option value='brand'>Brand</option>
                                  <option value='agency'>Agency</option>
                                </Form.Select>
                              )}
                            </td>
                            <td>
                              {/* {!values.users[index].editMode || !isMeThisMeInfo ? ( */}
                              <p className='m-0 py-2'>{values.users[index].email}</p>
                              {/* ) : (
                                <Form.Control
                                  type='text'
                                  name={`users[${index}].email`}
                                  value={values.users[index].email}
                                  onChange={handleChange}
                                />
                              )} */}
                            </td>
                            <td>
                              {!values.users[index].editMode || !isAdmin ? (
                                <p className='m-0 py-2'>{values.users[index].role}</p>
                              ) : (
                                <Form.Select
                                  name={`users[${index}].role`}
                                  value={values.users[index].role}
                                  onChange={handleChange}>
                                  <option></option>
                                  <option value='admin'>Admin</option>
                                  <option value='user'>User</option>
                                </Form.Select>
                              )}
                            </td>
                            <td>
                              {/* {!values.users[index].editMode || !isAdmin ? ( */}
                              <p className='m-0 py-2'>{values.users[index].subscription}</p>
                              {/* ) : (
                                <Form.Control
                                  type='text'
                                  name={`users[${index}].subscription`}
                                  value={values.users[index].subscription}
                                  onChange={handleChange}
                                />
                              )} */}
                            </td>
                            <td>
                              <p className='m-0 py-2'>
                                {values.users[index].status === 'action'
                                  ? `Active ${values.users[index].subscriptionEndDate}`
                                  : values.users[index].status === 'blocked'
                                  ? 'Not active'
                                  : ''}
                              </p>
                              {/* {!values.users[index].editMode ||
                              !isAdmin ||
                              (isAdmin && isMeThisMeInfo) ? (
                                <p className='m-0 py-2'>
                                  {values.users[index].status === 'action'
                                    ? 'Active'
                                    : values.users[index].status === 'blocked'
                                    ? 'Not active'
                                    : ''}
                                </p>
                              ) : (
                                <Form.Select
                                  name={`users[${index}].status`}
                                  value={values.users[index].status}
                                  onChange={handleChange}>
                                  <option></option>
                                  <option value='action'>Active</option>
                                  <option value='blocked'>Not active</option>
                                </Form.Select>
                              )} */}
                            </td>
                            <td>
                              {/* {!values.users[index].editMode || !isAdmin ? ( */}
                              <p className='m-0 py-2'>
                                {values.users[index].registrationDate
                                  ? moment(values.users[index].registrationDate).format(
                                      'DD/MM/YYYY'
                                    )
                                  : ''}
                              </p>
                              {/* ) : (
                                <DatePicker
                                  selected={values.users[index].registrationDate}
                                  onChange={(date: any) => setFieldValue(`users[${index}].registrationDate`, date)}
                                  dateFormat='dd/MM/yyyy'
                                  customInput={<Form.Control />}
                                />
                              )} */}
                            </td>
                            <td>
                              <div className='h-100 d-flex justify-content-start align-items-center'>
                                <button
                                  className='p-2 border-0 action-button'
                                  onClick={() => editHandler(index)}>
                                  <FontAwesomeIcon
                                    icon={
                                      values.users[index].editMode
                                        ? solid('floppy-disk')
                                        : solid('pen-to-square')
                                    }
                                    size='lg'
                                    className='actionButton'
                                  />
                                </button>
                                <button
                                  className='p-2 border-0 action-button'
                                  //  onClick={() => arrayHelpers.remove(index)}
                                  onClick={() => {
                                    onPaymentOpenHandler();
                                    setSelectedUserId(item.id);
                                  }}>
                                  <FontAwesomeIcon
                                    icon={solid('credit-card')}
                                    size='lg'
                                    className='actionButton'
                                  />
                                </button>
                                {/* <button className='p-2 border-0 action-button' onClick={() => arrayHelpers.remove(index)}>
                                  <FontAwesomeIcon icon={solid('trash')} size='lg' className='actionButton' />
                                </button> */}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  );
                }}
              />
            </FormikProvider>
          </tbody>
        </Table>
      </div>
      <PaymentModal
        isShowPaymentModal={isShowPaymentModal}
        onPaymentOpenHandler={onPaymentOpenHandler}
        selectedUserId={selectedUserId}
        setSelectedUserId={setSelectedUserId}
      />
    </div>
  );
};

export default UsersSettings;
