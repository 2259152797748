import axios from 'axios';
import { headers } from './headers';

export class CampaignGroupsApi {
  
  static createGroup = ({data,id}:{data:any,id:string}) =>{
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}/api/campaignGroup`,
      data,
      headers: {...headers(),'ad-account-id':id},
    })
  }

  static getAllGroups = ({id}:{id:string}) =>{
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/api/campaignGroup`,
      headers: {...headers(),'ad-account-id':id},
    })
  }
}