import { combineReducers } from 'redux';
import leads from '../slices/leads';
import auth from '../slices/auth';
import users from '../slices/users';
import payment from '../slices/payment';

export let rootReducer = combineReducers({
  leads,
  auth,
  users,
  payment,
});

export default rootReducer;
