import React, { FC, useEffect, useRef } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { getMeInfo, IMeInfo } from '../../../redux/slices/auth';
import { getAllSubscriptions, unsubscribe } from '../../../redux/slices/payment';
import SubscriptionForm from './SubscriptionForm';
import SubscriptionsTable from './SubscriptionsTable';
import SubscriptiosCards from './SubscriptiosCards';

interface IProps {
  meInfo: IMeInfo;
}

const SubscriptionPlans: FC<IProps> = ({ meInfo }) => {
  const arrayHelperRef = useRef<any>();
  const { subscriptions, plans } = useAppSelector(state => state.payment);

  const dispatch = useAppDispatch();

  // const addItemHandler = () => {
  //   arrayHelperRef.current.push({
  //     name: 'Standart tariff',
  //     plan: 'Monthly',
  //     price: '180',
  //     status: 'Active',
  //     editMode: false,
  //   });
  // };

  useEffect(() => {
    if (meInfo._id) dispatch(getAllSubscriptions(meInfo._id));
  }, [dispatch, meInfo._id]);

  const handleCancelSubscription = (subscriptionId: string) => {
    Swal.fire({
      title: 'Are you sure want to unsubscribe?',
      text: 'Your license key for the associated Subscription plan will be invalid after this!',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      icon: 'warning',
      confirmButtonColor: '#2137f9',
    }).then(async result => {
      if (result.isConfirmed) {
        const responce = await dispatch(unsubscribe(subscriptionId, meInfo._id));
        if (responce === 'success') {
          Swal.fire('Subscription cancelled successfully', '', 'success');
          dispatch(getMeInfo(meInfo._id));
        } else Swal.fire(responce, '', 'error');
      }
    });
  };

  // const autoCancelSubscription = async (subscriptionId: string) => {
  //   const responce = await dispatch(unsubscribe(subscriptionId, meInfo._id));
  //   if (responce === 'success') {
  //     dispatch(getMeInfo(meInfo._id));
  //   }
  // };

  return (
    <div className='subscriptionsSettings'>
      {meInfo.role === 'admin' && (
        <>
          {/* <div className='py-4'>
            <Form>
              <Row>
                <Col xs={6} sm={4} xxl={3}>
                  <Button className='w-100 mt-2' variant='success' onClick={addItemHandler}>
                    <i className='bi bi-plus-lg me-1'></i> Add new subscription
                  </Button>
                </Col>
              </Row>
            </Form>
          </div> */}

          <div className='py-4'>
            <SubscriptionsTable arrayHelperRef={arrayHelperRef} />
          </div>
        </>
      )}
      {meInfo.role === 'user' &&
        ((subscriptions && subscriptions.length > 0) ||
        meInfo.subscription.auto_renewal === false ? (
          <div>
            <Row xs={1} lg={2} xl={3} className='g-4'>
              {subscriptions && subscriptions.length > 0
                ? subscriptions.map(item => (
                    <SubscriptiosCards
                      key={item.id}
                      subscription={item}
                      plans={plans}
                      handleCancelSubscription={handleCancelSubscription}
                      // autoCancelSubscription={autoCancelSubscription}
                    />
                  ))
                : meInfo.subscription.subscription_name && (
                    <SubscriptiosCards userSubscription={meInfo.subscription} />
                  )}
            </Row>
          </div>
        ) : (
          <div className='py-4'>
            <SubscriptionForm />
          </div>
        ))}
    </div>
  );
};

export default SubscriptionPlans;
