import axios from 'axios';
import { headers, headersForHubspot, headersForLogin } from '../services/headers';

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/`,
  headers: headers(),
});

export const axiosInstanceHubspot = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/`,
  headers: headersForHubspot(),
});

export const axiosInstanceAuth = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/`,
  // headers: headers(),
});

export const axiosInstanceLogined = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/`,
  headers: headersForLogin(),
});
// axiosInstanceHubspot.defaults.timeout = 200;

axiosInstanceHubspot.interceptors.request.use(
  config => {
    const tokken =
      axiosInstanceHubspot &&
      axiosInstanceHubspot.defaults.headers &&
      axiosInstanceHubspot.defaults.headers['hubspot-access-code'];
    const hubspotTokken = localStorage.getItem('hubspot-access-code');
    if (!tokken && hubspotTokken) config.headers!['hubspot-access-code'] = hubspotTokken;
    return config;
  },
  error => Promise.reject(error)
);

axiosInstanceHubspot.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response.data.error === 'Request failed with status code 401') {
      localStorage.removeItem('hubspot-access-code');
      localStorage.setItem('page-before-redirect', window.location.pathname);
      window.location.href = `https://app.hubspot.com/oauth/authorize?client_id=1356caea-cfab-4996-8273-b1ff404909d7&scope=crm.objects.contacts.read%20crm.objects.companies.read%20crm.objects.deals.read&redirect_uri=${process.env.REACT_APP_BACKEND_URL}/api/hubspot/auth`;
    }
    return Promise.reject(error);
  }
);
