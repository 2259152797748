import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { FormEvent, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { authApi } from '../../api/authApi';
import { setMeInfo, successRegisterHandler } from '../../redux/slices/auth';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { ReactComponent as Visibility } from '../../assets/img/visibility.svg';
import { ReactComponent as VisibilityOff } from '../../assets/img/visibilityOff.svg';
import styles from './styles.module.scss';
const MIN_PASSWORD_LENGTH = 6;

const ResetPassword = () => {
  const history = useHistory();
  const { token }: { token: string } = useParams();
  const [credentials, setCredentials] = useState({
    password: '',
    confPassword: '',
  });
  const [btnLoading, setBtnLoading] = useState(false);
  const [typeInput, setTypeInput] = useState<'password' | 'text'>('password');

  const dispatch = useAppDispatch();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    try {
      setBtnLoading(true);
      e.preventDefault();
      const response = await authApi.resetPassword(token, { password: credentials.password });
      if (response.status !== 200) {
        throw '';
      }

      dispatch(setMeInfo(response.data.user));
      dispatch(successRegisterHandler({ expires_in: 1600, access_token: response.data.token }));

      Swal.fire({
        title: 'Success!',
        text: 'Password Changed Successfully!',
        icon: 'success',
        confirmButtonColor: '#2137f9',
      }).then(async result => {
        if (result.isConfirmed) {
          history.push('/linkedinAuth');
        }
      });
    } catch (err: any) {
      Swal.fire({
        title: 'Error!',
        text: err?.message ? err.message : 'Reset Password Link Invalid or Expired!',
        icon: 'error',
        confirmButtonColor: '#2137f9',
      });
    } finally {
      setBtnLoading(false);
    }
  };

  const onInputHandler = (fieldName: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({
      ...credentials,
      [fieldName]: e.target.value.toString().trim(),
    });
  };

  const handleClickShowPassword = () => {
    setTypeInput(typeInput === 'password' ? 'text' : 'password');
  };
  const isMainPasswordProblem =
    credentials.password.length > 0 && credentials.password.length < MIN_PASSWORD_LENGTH;
  const isConfirmPasswordProblem =
    credentials.confPassword.length > 0 &&
    (credentials.confPassword.length < MIN_PASSWORD_LENGTH ||
      credentials.password !== credentials.confPassword);

  return (
    <div>
      <div className='container'>
        <form onSubmit={onSubmit} style={{ width: '50%', margin: 'auto', marginTop: '10rem' }}>
          <Typography variant='h3' style={{ textAlign: 'center', marginBottom: '2rem' }}>
            Reset Password
          </Typography>
          <Grid container spacing={1}>
            <Grid xs={12} sm={12} item style={{ paddingLeft: 0 }}>
              <TextField
                onInput={onInputHandler('password')}
                style={{ width: '100%' }}
                type={typeInput}
                label='New Password'
                variant='outlined'
                value={credentials.password}
                error={isMainPasswordProblem}
                helperText={
                  isMainPasswordProblem ? `Minimum length ${MIN_PASSWORD_LENGTH} characters` : ' '
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        edge='end'>
                        {typeInput === 'password' ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid xs={12} sm={12} item style={{ paddingLeft: 0 }}>
              <TextField
                onInput={onInputHandler('confPassword')}
                style={{ width: '100%' }}
                type='password'
                variant='outlined'
                label='Confirm Password'
                value={credentials.confPassword}
                error={isConfirmPasswordProblem}
                helperText={isConfirmPasswordProblem ? 'Passwords must be the same' : ' '}
              />
            </Grid>
            <LoadingButton
              color='primary'
              variant='contained'
              type='submit'
              loading={btnLoading}
              disabled={
                isConfirmPasswordProblem ||
                isMainPasswordProblem ||
                !credentials.password.length ||
                !credentials.confPassword.length
              }
              className={styles.submitButton}
              style={{ marginTop: 10, width: '100%' }}>
              Submit
            </LoadingButton>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
