import React, { FC, useState } from 'react';
import { Table, Button, Form } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IMeInfo } from '../../redux/slices/auth';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { isActiveSubscription } from '../../common/activeSubscriptionCheck';

interface IProps {
  meInfo: IMeInfo;
}

const CrmSettings: FC<IProps> = ({ meInfo }) => {
  const [hubspotAccessCode, setHubspotAccessCode] = useState(
    localStorage.getItem('hubspot-access-code')
  );
  const history = useHistory();
  const addNewCrmHandler = () => {};

  const deleteHubspotTokenHandler = () => {
    localStorage.removeItem('hubspot-access-code');
    setHubspotAccessCode(null);
  };

  const handleConnectHubspot = () => {
    if (meInfo && isActiveSubscription(meInfo)) {
      window.location.href = `https://app.hubspot.com/oauth/authorize?client_id=${process.env.REACT_APP_HUBSPOT_CLIENT_ID}&scope=crm.objects.contacts.read%20crm.objects.companies.read%20crm.objects.deals.read&redirect_uri=${process.env.REACT_APP_BACKEND_URL}/api/hubspot/auth`;
    } else {
      Swal.fire({
        title: 'Upgrade to Inflows Premium',
        showCancelButton: true,
        confirmButtonText: 'Upgrade',
        icon: 'warning',
      }).then(async result => {
        if (result.isConfirmed) {
          history.push(`/dash/settings/subscription`);
        }
      });
    }
  };

  return (
    <div className='crmSettings'>
      {/* <div className='py-4'>
        <Form>
          <Row>
            <Col xs={6} sm={4} xxl={3}>
              <Button className='w-100 mt-2' variant='success' onClick={addNewCrmHandler}>
                <i className='bi bi-plus-lg me-1'></i> Add new CRM
              </Button>
            </Col>
          </Row>
        </Form>
      </div> */}
      <div className='py-4'>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>API key</th>
              {/* <th>Data import</th> */}
              <th>Actions</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p className='m-0 py-2'>1</p>
              </td>
              <td>
                <Form.Select>
                  <option value='hubspot'>Hubspot</option>
                </Form.Select>
              </td>
              <td>
                {/* <Form.Control type='text' /> */}
                {!hubspotAccessCode ? (
                  // <a
                  //   href={`https://app.hubspot.com/oauth/authorize?client_id=1356caea-cfab-4996-8273-b1ff404909d7&scope=crm.objects.contacts.read%20crm.objects.companies.read%20crm.objects.deals.read&redirect_uri=${process.env.REACT_APP_BACKEND_URL}/api/hubspot/auth`}>
                  <Button onClick={handleConnectHubspot}>Connect to Hubspot</Button>
                ) : (
                  // </a>
                  <p className='text-truncate' style={{ maxWidth: '150px' }}>
                    {hubspotAccessCode}
                  </p>
                )}
              </td>
              {/* <td>
                <Form.Select>
                  <option value='2'>Yes</option>
                  <option value='3'>No</option>
                </Form.Select>
              </td> */}
              <td>
                <div className='h-100 d-flex justify-content-start align-items-center'>
                  {/* <div className='p-2'>
                    <FontAwesomeIcon icon={solid('floppy-disk')} size='lg' />
                  </div>
                  <div className='p-2'>
                    <FontAwesomeIcon icon={solid('pen')} size='lg' />
                  </div> */}
                  {hubspotAccessCode && (
                    <button
                      className='p-2 border-0 action-button'
                      onClick={deleteHubspotTokenHandler}>
                      <FontAwesomeIcon icon={solid('trash')} size='lg' />
                    </button>
                  )}
                </div>
              </td>
              <td>
                <p className='m-0 py-2'>{hubspotAccessCode ? 'Active' : 'Not active'}</p>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default CrmSettings;
