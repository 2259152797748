import axios from 'axios';
import { headers } from './headers';

export class CreativesApi {
  
  static getAll = ({id}:{id:string}) =>{
    return axios({
      method: 'get',
      url:  `${process.env.REACT_APP_BACKEND_URL}/api/creative?campaign=${id}`,
      headers: {...headers(),'ad-account-id':id},
    })
  }
}
