import moment from 'moment';
import React, { FC, useEffect, useRef, useState } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import downloadURI from '../../../utils/downloadURI';
import { axiosInstanceLogined } from '../../../api/axiosInstance';
import { Axios } from 'axios';
import { Spinner } from 'react-bootstrap';

interface IProps {
  id: string;
  created: number;
  amount: number;
  currency: string;
  invoice: string;
}

const PaymentHistoryInfo: FC<IProps> = ({ id, created, amount, currency, invoice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceId, setInvoiceId] = useState<string | null>(null);

  useEffect(() => {
    let isCancelled = false;
    if (invoiceId && isLoading) {
      (async () => {
        try {
          const { data }: { data: { invoice: string } } = await axiosInstanceLogined.get(
            `/payment/invoice/${invoiceId}`
          );
          downloadURI(data.invoice, `${id}.pdf`);
        } catch (e) {
        } finally {
          if (!isCancelled) {
            setIsLoading(false);
            setInvoiceId(null);
          }
        }
      })();
    }
    return () => {
      isCancelled = true;
    };
  }, [invoiceId, isLoading]);

  const onDownloadHandler = () => {
    setIsLoading(true);
    setInvoiceId(invoice);
  };

  return (
    <tr key={id} style={{ height: '30px' }}>
      <td>
        <div className='p-2'>{id}</div>
      </td>
      <td>
        <div className='p-2'>{moment.unix(created).format('DD.MM.YYYY')}</div>
      </td>
      <td>
        <div className='p-2'>
          {amount / 100} {currency}
        </div>
      </td>
      {/* <td>
                      <div className='p-2'>Standard</div>
                    </td> */}

      <td>
        {isLoading ? (
          <div className='d-flex justify-content-center align-items-center'>
            <Spinner animation='border' variant='primary' />
          </div>
        ) : (
          <button className='p-2 border-0 action-button' onClick={onDownloadHandler}>
            <FontAwesomeIcon icon={solid('cloud-arrow-down')} size='lg' className='actionButton' />
          </button>
        )}
      </td>
    </tr>
  );
};
export default PaymentHistoryInfo;
