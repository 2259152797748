import axios from 'axios';
import {axiosInstance, axiosInstanceHubspot} from './axiosInstance';

export interface ILeadsRequestParam {
  campaignId: string;
  count: number;
  start: number;
}

export interface IDateRange {
  startyear: string;
  startmonth: string;
  startday: string;
  endyear: string;
  endmonth: string;
  endday: string;
}

export const leadsApi = {
  getLeadsListByCompaign(campaignId: string, count: number, dateRange: IDateRange, selectedAdAccountId: string) {
    return axiosInstance
      .get(
        `/analitics/leads?q=analytics&start=0&count=${count}&pivot=MEMBER_COMPANY&campaigns=${campaignId}&timeGranularity=ALL&sponsoredAccount=${selectedAdAccountId}&startyear=${dateRange.startyear}&startmonth=${dateRange.startmonth}&startday=${dateRange.startday}&endyear=${dateRange.endyear}&endmonth=${dateRange.endmonth}&endday=${dateRange.endday}&fields=impressions,pivotValue,dateRange,pivot`
      )
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response || 'Something went wrong';
      });
  },
};

// export const controllerHubspot = new AbortController();
// const CancelToken = axios.CancelToken;
// export let searchCancel: any = null;

export const leadsHubspotApi = {
  // getCompanies(fliltersGroup: any[]) {
  getCompanies(name: string) {
    return axiosInstanceHubspot
      .get(
        // `/analitics/hubspot?url=companies/search&data={"limit": 20, "properties": ["name", "phone", "country", "industry", "website", "domain", "contacts"]}`
        `analitics/hubspot?url=companies/search&data={"filterGroups":[{"filters":[{"propertyName": "name","operator": "EQ","value": "${name}"}]}], "properties": ["name"]}` // `analitics/hubspot?url=companies/search&data={"filterGroups":${JSON.stringify(fliltersGroup)}, "properties": ["name"]}`
        // `analitics/hubspot?url=companies/search&data={"limit": 100, "properties": ["name"]}`
      )
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response || 'Something went wrong';
      });
  },
  getContacts(id: number) {
    return axiosInstanceHubspot
      .get(
        `analitics/hubspot?url=contacts/search&data={
          "filters": [
            {
              "propertyName": "associations.company",
              "operator": "EQ",
              "value": ${id}
            }
          ]
        }`
      )
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response || 'Something went wrong';
      });
  },
  getDeals(id: number) {
    return axiosInstanceHubspot
      .get(
        `analitics/hubspot?url=deals/search&data={
          "filters": [
            {
              "propertyName": "associations.company",
              "operator": "EQ",
              "value": ${id}
            }
          ]
        }`
      )
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response || 'Something went wrong';
      });
  },
};
