import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import Lead from '../../pages/Lead';
import {ThunkAction} from 'redux-thunk';
import {IDateRange, leadsApi, leadsHubspotApi} from '../../api/leadsApi';
import {AnyAction} from 'redux';
import {RootState} from '../store';
import {resolve} from 'dns';
import {toast} from 'react-toastify';
// ----------------------------------------------------------------------
// export interface ILead {
//   id: string;
//   name: string;
//   adsCampaign: number;
//   impressions: number;
//   deals: number;
//   revenue: number;
// }

export interface ILeads {
  elements: any[];
  paging: any;
}

export interface IHubspotData {
  companyHubspotInfo: any;
  contacts: any;
  deals: any;
}
export interface LeadsInitialState {
  // selectedLead: any | null;
  leadsList: ILeads | null;
  selectedCompaign: any | null;
  compaignsListForDropDrown: any[] | null;
  hubspotDataList: any[] | null;
  isFetchingDataFromHubspot: boolean;
  isFetchingDataFromLinkedIn: boolean;
  hubspotErrorRequest: string | null;
}

const initialState: LeadsInitialState = {
  // selectedLead: null,
  leadsList: null,
  selectedCompaign: null,
  compaignsListForDropDrown: null,
  hubspotDataList: null,
  isFetchingDataFromHubspot: false,
  isFetchingDataFromLinkedIn: true,
  hubspotErrorRequest: null,
};

const slice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    setIsFetchingDataFromLinkedIn(state, action: PayloadAction<boolean>) {
      state.isFetchingDataFromLinkedIn = action.payload;
    },
    setLeadsList(state, action: PayloadAction<ILeads | null>) {
      state.leadsList = action.payload;
    },
    setSelectedCompaign(state, action: PayloadAction<any | null>) {
      state.selectedCompaign = action.payload;
    },
    setCompaignsListForDropDrown(state, action: PayloadAction<any[] | null>) {
      state.compaignsListForDropDrown = action.payload;
    },
    setLeadsInfoFromHubspot(state, action: PayloadAction<IHubspotData | null>) {
      if (action.payload) {
        if (state.hubspotDataList) state.hubspotDataList = [...state.hubspotDataList, action.payload];
        else state.hubspotDataList = [action.payload];
      } else state.hubspotDataList = null;
    },
    setIsFetchingDataFromHubspot(state, action: PayloadAction<boolean>) {
      state.isFetchingDataFromHubspot = action.payload;
    },
    setHubspotErrorRequest(state, action: PayloadAction<string | null>) {
      state.hubspotErrorRequest = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {setSelectedCompaign, setLeadsList, setCompaignsListForDropDrown, setLeadsInfoFromHubspot, setHubspotErrorRequest} =
  slice.actions;

// ---------------Country---------------------------------

export const getLeadsListByCampaign =
  (
    campaignId: string,
    count: number,
    dateRange: IDateRange,
    selectedAdAccountId: string
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      dispatch(slice.actions.setIsFetchingDataFromLinkedIn(true));
      const response = await leadsApi.getLeadsListByCompaign(campaignId, count, dateRange, selectedAdAccountId);
      if (response.status === 200) {
        if (response.data.analitics && response.data.analitics) {
          dispatch(slice.actions.setLeadsList(response.data.analitics));
        } else dispatch(slice.actions.setLeadsList(null));
      } else throw new Error('Error request');
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(slice.actions.setIsFetchingDataFromLinkedIn(false));
    }
  };

export let shouldSkip = false;
export const stoperFunc = (value: boolean) => (shouldSkip = value);

export const getCompaniesFromHubSpot =
  (companiesList: any[]): ThunkAction<void, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      dispatch(slice.actions.setIsFetchingDataFromHubspot(true));
      const companiesListFromHubspot: any[] = [];
      let promise = Promise.resolve();

      // const fliltersGroup: any[] = [];
      companiesList.forEach((item, index, array) => {
        if (shouldSkip) {
          return;
        }
        const name = item['pivotValue~'] && item['pivotValue~'].localizedName;
        // fliltersGroup.push({filters: [{propertyName: 'name', operator: 'EQ', value: name}]});

        if (name) {
          promise = promise.then(async function () {
            if (!shouldSkip) {
              if (index === array.length - 1) await requestCompany(name);
              else requestCompany(name);
              return new Promise(function (resolve) {
                setTimeout(resolve, 300);
              });
            }
            if (shouldSkip && index === array.length - 1) {
              // stoperFunc(false);
              return new Promise(function (resolve) {
                resolve();
              });
            }
          });
        }
      });

      // console.log(fliltersGroup);
      // const responseCompanies = await leadsHubspotApi.getCompanies(fliltersGroup);

      // console.log(responseCompanies);

      const requestCompany = async (companyName: string) => {
        try {
          const responseCompanies = await leadsHubspotApi.getCompanies(companyName);
          if (responseCompanies.status === 200) {
            const companyHubspotInfo =
              responseCompanies.data.analitics?.total > 0 ? responseCompanies.data.analitics?.results[0] : null;
            if (companyHubspotInfo) companiesListFromHubspot.push(companyHubspotInfo);
          } else throw new Error(responseCompanies.data.error || 'Error request');
        } catch (error: any) {
          console.log(error);
          if (error !== 'Request failed with status code 401') {
            console.log(error);
            dispatch(slice.actions.setHubspotErrorRequest(companyName + ' request error! Try again.'));
          }
        } finally {
          return 'done';
        }
      };

      promise.then(function () {
        // console.log('Loop finished.');
        // console.log('shouldSkip', shouldSkip);
        if (companiesListFromHubspot?.length > 0 && !shouldSkip) {
          companiesListFromHubspot.forEach((item, index, array) => {
            const isLastItem = index === array.length - 1;
            setTimeout(
              () => {
                requestCompanyInfo(item, isLastItem);
              },
              index === 0 ? 0 : 520 * index
            );
          });
        } else {
          stoperFunc(false);
          dispatch(slice.actions.setIsFetchingDataFromHubspot(false));
        }
      });

      const requestCompanyInfo = async (item: any, isLastItem: boolean) => {
        if (shouldSkip) return;
        const companyId = item.id;

        const responseContacts = await leadsHubspotApi.getContacts(companyId);
        const responseDeals = await leadsHubspotApi.getDeals(companyId);

        if (isLastItem) {
          stoperFunc(false);
          dispatch(slice.actions.setIsFetchingDataFromHubspot(false));
        }
        const hubspotData = {
          companyHubspotInfo: item,
          contacts: responseContacts && responseContacts.data.analitics ? responseContacts.data.analitics : null,
          deals: responseDeals && responseDeals.data.analitics ? responseDeals.data.analitics : null,
        };
        if (hubspotData.contacts || hubspotData.contacts) {
          dispatch(slice.actions.setLeadsInfoFromHubspot(hubspotData));
        }
      };
    } catch (error) {
      console.log(error);
    }
  };

// export const getCompaniesFromHubSpot =
//   (name: string): ThunkAction<void, RootState, unknown, AnyAction> =>
//   async dispatch => {
//     try {
//       const responseCompanies = await leadsHubspotApi.getCompanies(name);
//       console.log(responseCompanies);
//       if (responseCompanies.status === 200) {
//         const companyId = responseCompanies.data.analitics.results[0].id;
//         const responseContacts = await leadsHubspotApi.getContacts(companyId);
//         const responseDeals = await leadsHubspotApi.getDeals(companyId);

//         const hubspotData = {
//           companyHubspotInfo:
//             responseCompanies.data.analitics && responseCompanies.data.analitics.results[0]
//               ? responseCompanies.data.analitics.results[0]
//               : null,
//           contacts: responseContacts && responseContacts.data.analitics ? responseContacts.data.analitics : null,
//           deals: responseDeals && responseDeals.data.analitics ? responseDeals.data.analitics : null,
//         };

//         console.log(responseContacts);
//         console.log(responseDeals);
//         if (hubspotData.contacts || hubspotData.contacts) {
//           dispatch(slice.actions.setLeadsInfoFromHubspot(hubspotData));
//         }
//       } else throw new Error('Error request');
//     } catch (error) {
//       console.log(error);
//     }
//   };
