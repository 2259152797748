import React, { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Auth } from './pages/Auth';
import { SelectedAdAccountIdProvider } from './context/SelectedAdAccount';
import { SelectedCampaignGroupProvider } from './context/SelectedCampaignGroup';
import { Login } from './pages/Login';
import 'react-datetime/css/react-datetime.css';
import 'react-toastify/dist/ReactToastify.css';
import { DefaultLayout } from './components/DefaultLayout';
import './styles/style.scss';
import WelcomPage from './pages/WelcomPage';
import { decodeToken } from 'react-jwt';
import { useAppDispatch, useAppSelector } from './hooks/reduxHooks';
import { getMeInfo, IMeInfo } from './redux/slices/auth';
import moment from 'moment';
import { deleteSubscriptionFromBase } from './redux/slices/payment';
import ResetPassword from './pages/WelcomPage/ResetPassword';

function App() {
  const dispatch = useAppDispatch();
  const [LinkedInToken, setLinkedInToken] = useState(localStorage.getItem('linkedin-access-code'));
  const { meInfo } = useAppSelector(state => state.auth);

  const setToken = (token: string) => {
    setLinkedInToken(token);
  };

  useEffect(() => {
    const meInfos = localStorage.getItem('access_token');
    if (meInfos) {
      const myDecodedToken: IMeInfo | null = decodeToken(meInfos);
      if (myDecodedToken) dispatch(getMeInfo(myDecodedToken._id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      meInfo &&
      meInfo.subscription.subscriptionId &&
      meInfo.subscription.end_date &&
      meInfo.subscription.auto_renewal === false
    ) {
      const dateEnd = moment.unix(meInfo.subscription.end_date);
      const dateNow = moment();
      if (dateEnd < dateNow) {
        dispatch(deleteSubscriptionFromBase(meInfo._id)).then((responce: string) => {
          if (responce === 'success') dispatch(getMeInfo(meInfo._id));
        });
      }
    }
  }, [dispatch, meInfo]);

  return (
    <SelectedCampaignGroupProvider>
      <SelectedAdAccountIdProvider>
        <ToastContainer
          position='top-right'
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={3}
        />
        <Switch>
          <Route path='/dash'>{LinkedInToken ? <DefaultLayout /> : <Redirect to='/' />}</Route>
          <Route path='/auth'>
            <Auth setToken={setToken} />
          </Route>
          <Route path='/login'>
            <Login />
          </Route>
          <Route path='/linkedinAuth' exact>
            <LinkedinAuth />
          </Route>
          <Route path='/resetPassword/:token' component={ResetPassword} />
          <Route path='/' exact component={WelcomPage} />
          <Route>
            <div className='d-flex'>
              <h1 className='m-auto mt-5'>404</h1>
            </div>
          </Route>
        </Switch>
      </SelectedAdAccountIdProvider>
    </SelectedCampaignGroupProvider>
  );
}

const LinkedinAuth = () => {
  useEffect(() => {
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=78bv1x7odjwzra&redirect_uri=${process.env.REACT_APP_BACKEND_URL}/api/linkedin/auth&state=foobar&scope=rw_ads%20r_liteprofile%20r_ads_reporting`;
  }, []);
  return <></>;
};

export default App;
