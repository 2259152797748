import axios from 'axios';
import { headers } from './headers';

export class AdAccountApi {
  
  static getAllAccounts = () =>{
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/api/adaccount`,
      headers: headers(),
    })
  }

  static create = (data:any) =>{
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}/api/adaccount`,
      data,
      headers: headers(),
    })
  }
}