import React, { FC } from 'react';
import { Card, Button, Col } from 'react-bootstrap';
import moment from 'moment';
import { IMeInfoSubscription } from '../../../redux/slices/auth';

interface IProps {
  subscription?: any;
  plans?: any;
  handleCancelSubscription?: (id: string) => void;
  userSubscription?: IMeInfoSubscription;
}
const SubscriptiosCards: FC<IProps> = ({
  subscription,
  plans,
  handleCancelSubscription,
  userSubscription,
}) => {
  const currentPlan = plans && plans.find((item: any) => item.id === subscription.plan.product);

  const planName = currentPlan
    ? currentPlan.name
    : (userSubscription && userSubscription.subscription_name) || '-';

  const interval =
    subscription &&
    (subscription.plan.interval_count === 1
      ? subscription.plan.interval
      : `${subscription.plan.interval_count} ${subscription.plan.interval}`);

  return (
    <Col>
      <Card className='text-center'>
        <Card.Header>{planName}</Card.Header>
        <Card.Body>
          <Card.Title>
            {subscription
              ? `$${subscription.plan.amount / 100}/${interval}`
              : userSubscription
              ? `$${userSubscription.amount}`
              : '-'}
          </Card.Title>
          <Card.Text>
            Subscription ID:{' '}
            {subscription ? subscription.id : userSubscription && userSubscription.subscriptionId}
          </Card.Text>
          <Card.Text>
            <b>
              {subscription
                ? `Next Payment: ` +
                  moment.unix(subscription.current_period_end).format('MMMM Do YYYY')
                : userSubscription && userSubscription.end_date
                ? `Subscription expiration: ` +
                  moment.unix(userSubscription.end_date).format('MMMM Do YYYY')
                : '-'}
            </b>
          </Card.Text>
          {handleCancelSubscription && (
            <Button variant='primary' onClick={() => handleCancelSubscription(subscription.id)}>
              Unsubscribe
            </Button>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default SubscriptiosCards;
