import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Dashboard } from '../pages/Dashboard/Dashboard';
import { Leads } from '../pages/Leads/';

import { LeftSidebar } from './LeftSidebar';
import Settings from '../pages/Settings';

import { toast } from 'react-toastify';
import axios from 'axios';
import { headers, headersForLogin } from '../services/headers';
import { Spinner } from 'react-bootstrap';

interface Props {}

export const DefaultLayout: React.FC<Props> = () => {
  // const history = useHistory();
  // const [isLoadingToVerified, setIsLoadingToVerified] = useState(true);
  //
  // useEffect(() => {
  //   let isCancelled = false;
  //
  //   (async () => {
  //     setIsLoadingToVerified(true);
  //     try {
  //       await axios.get('linkedin/me', {
  //         baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/`,
  //         headers: { ...headers(), ...headersForLogin() },
  //       });
  //     } catch (e) {
  //       if (!isCancelled) {
  //         localStorage.removeItem('linkedin-access-code');
  //         toast.error('Only 1 LinkedIn account allowed!' + '\n' + 'Wait 10 minutes', {
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //         });
  //         localStorage.removeItem('linkedin-access-code');
  //         localStorage.removeItem('userData');
  //         localStorage.removeItem('loginDetails');
  //
  //         localStorage.removeItem('access_token');
  //         localStorage.removeItem('access_token_expires_in');
  //
  //         history.push('/');
  //       }
  //     } finally {
  //       setIsLoadingToVerified(false);
  //     }
  //   })();
  //
  //   return () => {
  //     isCancelled = true;
  //   };
  // }, []);
  //
  // if (isLoadingToVerified) {
  //   return (
  //     <div className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
  //       <Spinner animation='border' variant='primary' />
  //     </div>
  //   );
  // }

  return (
    <div className='d-flex'>
      <LeftSidebar />
      <div className='flex-grow-1'>
        <Switch>
          <Route exact path='/dash/campaigns' component={Dashboard} />
          <Route path='/dash/campaigns/:id' component={Leads} />
          {/* <Route path='/dash/leads' component={Leads} /> */}
          {/* <Route path='/dash/lead/:id' component={Lead} /> */}
          <Route path='/dash/settings' component={Settings} />
          <Redirect from='/dash' to='/dash/campaigns' />
        </Switch>
      </div>
    </div>
  );
};
