import React, { useContext, useState } from 'react';
import { Nav, Navbar, Button, Modal, Form, FloatingLabel, Row, Col } from 'react-bootstrap';
import { SelectedAdAccountIdContext } from '../context/SelectedAdAccount';
import { SelectedCampaignGroupContext } from '../context/SelectedCampaignGroup';
import { CampaignApi } from '../services/CampaignApi';
import { LeftSidebar } from './LeftSidebar';
import Datetime from 'react-datetime';
import moment from 'moment';
import { toast } from 'react-toastify';

interface Props {
  UpdateValues: () => void;
  title: string | null;
}

export const Layout: React.FC<Props> = ({ children, UpdateValues, title }) => {
  const [inputDate, setInputDate] = useState({
    start: moment(),
    end: moment(),
  });
  const [inputTime, setInputTime] = useState({
    start: moment(),
    end: moment(),
  });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedDays, setSelectedDays] = useState([0]);
  // const [mode, setMode] = useState(0);
  const { selectedCampaignGroupId } = useContext(SelectedCampaignGroupContext);
  const { selectedAdAccountId } = useContext(SelectedAdAccountIdContext);

  return (
    <div>
      <div className='d-flex'>
        {/* <LeftSidebar /> */}
        <div id='page-content-wrapper'>
          <Navbar bg='light' className='border-bottom px-3 justify-content-between'>
            <Navbar.Brand>{title || 'Campaigns'}</Navbar.Brand>
            {/*{selectedCampaignGroupId && (*/} {/* todo add Campaign */}
            {/*  <Nav className='ms-auto'>*/}
            {/*    <Button variant='success' onClick={handleShow}>*/}
            {/*      <i className='bi bi-plus-lg me-1'></i> Campaign*/}
            {/*    </Button>*/}
            {/*  </Nav>*/}
            {/*)}*/}
            <Nav className='ms-2'>
              <Button variant='success' onClick={() => window.location.reload()}>
                <i className='bi bi-arrow-clockwise'></i>
              </Button>
            </Nav>
          </Navbar>
          {children}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Form
          style={{
            maxHeight: '90vh',
            overflow: 'auto',
          }}
          method='POST'
          onSubmit={e => {
            e.preventDefault();
            const toastId = toast.loading('Creating new group');
            const formData = new FormData(e.target as HTMLFormElement);
            let formDataObj = Object.fromEntries(formData.entries());
            let toSend: any = {
              ...formDataObj,
              dailyBudget: {
                amount: formDataObj.dailyBudget,
                currency: formDataObj.dailyBudgetCurrency,
              },
              unitCost: {
                amount: formDataObj.unitCost,
                currency: formDataObj.dailyBudgetCurrency,
              },
              runSchedule: {
                start: inputDate.start.valueOf(),
                end: inputDate.end.valueOf(),
              },
              locale: {
                country: 'US',
                language: 'en',
              },
              startTime: inputTime.start.valueOf(),
              endTime: inputTime.end.valueOf(),
              selectedDays: selectedDays,
            };

            delete toSend.dailyBudgetCurrency;

            CampaignApi.create({
              id: selectedAdAccountId || '',
              data: toSend,
            })
              .then(res => {
                UpdateValues();
                handleClose();
                toast.update(toastId, {
                  render: 'Success',
                  type: 'success',
                  isLoading: false,
                  autoClose: 2000,
                });
              })
              .catch(rej => {
                toast.update(toastId, {
                  render: 'Error',
                  type: 'error',
                  isLoading: false,
                  autoClose: 2000,
                });
              });
          }}>
          <Modal.Header closeButton>
            <Modal.Title>Create campaign</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className='mb-3'>
              <Form.Label>Campaign name</Form.Label>
              <Form.Control type='text' placeholder='Enter name' name='name' minLength={4} />
            </Form.Group>
            <Form.Control
              readOnly
              hidden
              name='campaignGroup'
              value={'urn:li:sponsoredCampaignGroup:' + selectedCampaignGroupId}
            />

            <Form.Control hidden name='dailyBudgetCurrency' value={'USD'} />

            <Form.Check
              readOnly
              hidden
              name='audienceExpansionEnabled'
              checked={false}
              type='checkbox'
            />
            <Form.Group className='mb-3'>
              <FloatingLabel label='Cost Type'>
                <Form.Select aria-label='Cost Type' name='costType'>
                  <option value='CPM'>Cost per thousand advertising impressions.</option>
                  <option value='CPC'>Cost per individual click on the associated link.</option>
                  <option value='CPV'>Cost per view for video ads.</option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Form.Group className='mb-3'>
              <FloatingLabel label='Creative Selection'>
                <Form.Select aria-label='Creative Selection' name='creativeSelection'>
                  <option value='ROUND_ROBIN'>
                    Rotate through available creatives to serve them as evenly as possible.
                  </option>
                  <option value='OPTIMIZED'>
                    Bias selection taking into account such as expected performance. Not available
                    for Message and Conversation Ads
                  </option>
                </Form.Select>
              </FloatingLabel>
            </Form.Group>
            <Row>
              <Form.Group className='mb-3' as={Col}>
                <Form.Label>Budget</Form.Label>
                <Form.Control type='number' placeholder='Enter amount' name='dailyBudget' />
              </Form.Group>
              <Form.Group className='mb-3' as={Col}>
                <Form.Label>Unit Cost</Form.Label>
                <Form.Control type='number' placeholder='Enter amount' name='unitCost' />
              </Form.Group>
            </Row>
            {/* <Row>
              <Form.Group className='mb-3' as={Col}>
                <Form.Check
                  inline
                  label='Default'
                  type='radio'
                  checked={mode === 0}
                  onClick={(e) => setMode(0)}
                />
              </Form.Group>
              <Form.Group className='mb-3' as={Col}>
                <Form.Check
                  inline
                  label='Custom Days and time'
                  type='radio'
                  checked={mode === 1}
                  onClick={(e) => setMode(1)}
                />
              </Form.Group>
            </Row> */}
            <Row>
              {/* {mode === 0 ? (
                <Row>
                  <Form.Group className='mb-3' as={Col}>
                    <Form.Label>Start Date/Time</Form.Label>
                    <Datetime
                      value={inputDate.start}
                      onChange={(date) =>
                        setInputDate({
                          ...inputDate,
                          start: typeof date === "string" ? moment() : date,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group className='mb-3' as={Col}>
                    <Form.Label>End Date/Time</Form.Label>
                    <Datetime
                      value={inputDate.end}
                      onChange={(date) =>
                        setInputDate({
                          ...inputDate,
                          end: typeof date === "string" ? moment() : date,
                        })
                      }
                    />
                  </Form.Group>
                </Row>
              ) : ( */}
              <>
                <Row>
                  <Form.Group className='mb-3' as={Col}>
                    <Form.Label>Start Date</Form.Label>
                    <Datetime
                      timeFormat={false}
                      value={inputDate.start}
                      onChange={date =>
                        setInputDate({
                          ...inputDate,
                          start: typeof date === 'string' ? moment() : date,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group className='mb-3' as={Col}>
                    <Form.Label>End Date</Form.Label>
                    <Datetime
                      timeFormat={false}
                      value={inputDate.end}
                      onChange={date =>
                        setInputDate({
                          ...inputDate,
                          end: typeof date === 'string' ? moment() : date,
                        })
                      }
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className='mb-3' as={Col}>
                    <Form.Label>Start Time</Form.Label>
                    <Datetime
                      dateFormat={false}
                      value={inputTime.start}
                      onChange={date =>
                        setInputTime({
                          ...inputTime,
                          start: typeof date === 'string' ? moment() : date,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group className='mb-3' as={Col}>
                    <Form.Label>End Time</Form.Label>
                    <Datetime
                      dateFormat={false}
                      value={inputTime.end}
                      onChange={date =>
                        setInputTime({
                          ...inputTime,
                          end: typeof date === 'string' ? moment() : date,
                        })
                      }
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className='mb-3' as={Col}>
                    <Form.Label>Days</Form.Label>
                    <div className='mb-3'>
                      {[
                        { index: 0, label: 'Sunday' },
                        { index: 1, label: 'Monday' },
                        { index: 2, label: 'Tuesday' },
                        { index: 3, label: 'Wednesday' },
                        { index: 4, label: 'Thursday' },
                        { index: 5, label: 'Friday' },
                        { index: 6, label: 'Saturday' },
                      ].map((item, index) => (
                        <Form.Check
                          key={index}
                          inline
                          label={item.label}
                          type='checkbox'
                          checked={selectedDays.includes(item.index)}
                          onClick={e => {
                            if (selectedDays.includes(item.index)) {
                              setSelectedDays(selectedDays.filter(val => val !== item.index));
                            } else {
                              setSelectedDays([...selectedDays, item.index]);
                            }
                          }}
                        />
                      ))}
                    </div>
                  </Form.Group>
                </Row>
              </>
              {/* )} */}
            </Row>

            <FloatingLabel label='Status' className='mb-3'>
              <Form.Select aria-label='Status' name='status'>
                <option value='ACTIVE'>Active</option>
                <option value='DRAFT'>Draft</option>
                <option value='PAUSED'>Paused</option>
                <option value='ARCHIVED'>Archived</option>
                <option value='COMPLETED'>Completed</option>
                <option value='CANCELED'>Cancelled</option>
              </Form.Select>
            </FloatingLabel>
            <FloatingLabel label='Type'>
              <Form.Select aria-label='Type' name='type'>
                <option value='TEXT_AD'>
                  {' '}
                  Text-based ads that show up in the right column or top of the page on LinkedIn.
                </option>
                <option value='SPONSORED_UPDATES'>
                  Native ads that promote a company's content updates in the LinkedIn feed.
                </option>
                <option value='SPONSORED_INMAILS'>
                  Personalized messages with a call-to-action button delivered to a LinkedIn's
                  member inbox.
                </option>
                <option value='DYNAMIC'>Ads that are dynamically personalized.</option>
              </Form.Select>
            </FloatingLabel>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Close
            </Button>
            <Button variant='primary' type='submit'>
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};
