import { IAuthApiRegistration } from '../interfaces/apiInterfaces';
import { axiosInstanceAuth } from './axiosInstance';

export const authApi = {
  registration(dataObj: IAuthApiRegistration) {
    return axiosInstanceAuth
      .post(`/auth/register`, dataObj)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response.data || 'Something went wrong';
      });
  },
  login(name: string, pass: string) {
    return axiosInstanceAuth
      .post(`/auth/login`, {
        username: name,
        password: pass,
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response.data || 'Something went wrong';
      });
  },
  forgotPassword(formData: { email: string }) {
    return axiosInstanceAuth
      .post(`/auth/forgotPassword`, formData)
      .then(response => {
        return response;
      })
      .catch((error: any) => {
        return error.response || 'Something went wrong';
      });
  },
  resetPassword(token: string, formData: { password: string }) {
    return axiosInstanceAuth
      .patch(`/auth/resetPassword/${token}`, formData)
      .then(response => {
        return response;
      })
      .catch((error: any) => {
        return error.response || 'Something went wrong';
      });
  },
};
