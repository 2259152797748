import React from "react";
import { Form, Button, Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { HomeLayout } from "../components/HomeLayout";
import { auth } from "@appstrax/auth";
import { toast } from "react-toastify";

interface Props {}

export const Login = (props: Props) => {
  const history = useHistory();
  return (
    <HomeLayout>
      <Card style={{ maxWidth: 450 }} className='p-4 m-auto mt-5'>
        <h2 className='text-center mb-3'>Login to dashboard</h2>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            const toastId = toast.loading("Logging in...");
            const formData = new FormData(e.target as HTMLFormElement);
            let formDataObj = Object.fromEntries(formData.entries());
            let toSend = {
              email: formDataObj.email as string,
              password: formDataObj.password as string,
            };
            auth
              .login(toSend)
              .then((user) => {
                toast.update(toastId, {
                  render: "Logged In",
                  type: "success",
                  autoClose: 2000,
                  isLoading: false,
                });
                localStorage.setItem("loginDetails", JSON.stringify(user));
                history.push("/");
              })
              .catch((err) => {
                console.log(err);
              });
          }}
        >
          <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label>Email address</Form.Label>
            <Form.Control type='email' placeholder='Enter email' name='email' />
            <Form.Text className='text-muted'>
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group className='mb-3' controlId='formBasicPassword'>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Password'
              name='password'
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='formBasicCheckbox'>
            <Form.Check
              type='checkbox'
              label={
                <p>
                  I accept <Link to='/terms'>terms and conditions</Link>
                </p>
              }
            />
            <Form.Text className='text-muted'>
              <Link to='/register'>Create new account</Link>
            </Form.Text>
          </Form.Group>
          <Button variant='primary' type='submit'>
            Login
          </Button>
        </Form>
      </Card>
    </HomeLayout>
  );
};
