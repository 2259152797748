import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router';

interface Props {
  setToken: (token: string) => void;
}

export const Auth = (props: Props) => {
  let query = new URLSearchParams(useLocation().search);
  const token = query.get('linkedin-access-code');
  const tokenHubSpot = query.get('hubspot-access-code');

  useEffect(() => {
    if (token) {
      props.setToken(token);
      localStorage.setItem('linkedin-access-code', token);
      localStorage.setItem(
        'userData',
        JSON.stringify({
          firstName: query.get('firstname'),
          lastName: query.get('lastname'),
        })
      );
    }
    if (tokenHubSpot) {
      localStorage.setItem('hubspot-access-code', tokenHubSpot);
    }
  }, [props, token, tokenHubSpot]);

  if (tokenHubSpot) return <Redirect to='/dash/settings' />;
  return <Redirect to='/dash' />;
};
