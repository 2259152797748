import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Box, Typography } from '@mui/material';
import StripeForm from './StripeForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { getAllPlans } from '../../../redux/slices/payment';

const style = {
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #ccc',
  p: 4,
};

interface Props {}

const stripeKey =
  process.env.REACT_APP_STRIPE_ENV === 'PRODUCTION'
    ? process.env.REACT_APP_STRIPE_KEY_PROD
    : process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(stripeKey!);

const SubscriptionForm = (props: Props) => {
  const [newSubscriptionOpen, setNewSubscriptionOpen] = useState(false);
  const { plans } = useAppSelector(state => state.payment);

  const [priceId, setPriceId] = useState<string>();
  const [subscriptionName, setSubscriptionName] = useState<string>();

  const dispatch = useAppDispatch();

  const newSubscriptionHandler = (plan: any) => {
    setPriceId(plan.default_price);
    const planName = `${plan.name} (${plan.plans[0].amount}/${plan.plans[0].interval})`;
    setSubscriptionName(planName);
    setNewSubscriptionOpen(true);
  };

  useEffect(() => {
    dispatch(getAllPlans());
  }, [dispatch]);

  return (
    <div className='subscriptionForm'>
      <div className='mb-4'>
        {plans &&
          plans.length > 0 &&
          plans.map(item => (
            <Button
              key={item.id}
              className='mb-2 me-2'
              variant='success'
              onClick={() => newSubscriptionHandler(item)}>
              <i className='bi bi-plus-lg me-1'></i> {item.name}
            </Button>
          ))}
      </div>
      {newSubscriptionOpen && priceId && (
        <Box sx={style}>
          <Typography variant='subtitle2' component='b'>
            {subscriptionName}
          </Typography>
          <hr style={{ marginBottom: 10, marginTop: 5 }} />
          <Elements stripe={stripePromise}>
            <StripeForm priceId={priceId} setNewSubscriptionOpen={setNewSubscriptionOpen} />
          </Elements>
        </Box>
      )}
    </div>
  );
};

export default SubscriptionForm;
