import { ISubscriptionObject } from '../interfaces/paymentsInterfaces';
import { axiosInstanceLogined } from './axiosInstance';

export const paymentApi = {
  createSubscription(data: ISubscriptionObject) {
    return axiosInstanceLogined
      .post(`/payment/subscriptions`, data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response || 'Something went wrong';
      });
  },
  saveSubscriptionToBase(subscription: ISubscriptionObject, userId: string) {
    return axiosInstanceLogined
      .post(`/payment/subscriptions-save`, { subscription, userId })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response || 'Something went wrong';
      });
  },
  getPlans() {
    return axiosInstanceLogined
      .get(`/payment/plans`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response || 'Something went wrong';
      });
  },
  getAllSubscriptions(userId: string) {
    return axiosInstanceLogined
      .get(`/payment/subscriptions?userId=${userId}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response || 'Something went wrong';
      });
  },
  unsubscribe(subscriptionId: string, userId: string) {
    return axiosInstanceLogined
      .delete(`/payment/subscriptions/${subscriptionId}?userId=${userId}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response || 'Something went wrong';
      });
  },
  deleteSubscription(userId: string) {
    return axiosInstanceLogined
      .delete(`/payment/subscription/delete?userId=${userId}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response || 'Something went wrong';
      });
  },
  getPaymentHistory(userId: string) {
    return axiosInstanceLogined
      .get(`/payment/history?userId=${userId}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response || 'Something went wrong';
      });
  },
};
