import { createSlice, EmptyObject, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';

import { ThunkAction } from 'redux-thunk';

import { AnyAction } from 'redux';
import { RootState } from '../store';

import { authApi } from '../../api/authApi';
import moment from 'moment';
import { IAuthApiRegistration, IUpdateUserInfo } from '../../interfaces/apiInterfaces';
import { usersApi } from '../../api/usersApi';

// ----------------------------------------------------------------------

export interface IMeInfoSubscription {
  subscriptionId: string | null;
  end_date: number | null;
  subscription_name: string | null;
  auto_renewal: boolean | null;
  amount: string | null;
}
export interface IMeInfo {
  agency_or_brand: string;
  estimated_monthly_ad_spend: number;
  exp: number;
  iat: number;
  infousername: string;
  role: string;
  status: string;
  subscription: IMeInfoSubscription;
  username: string;
  _id: string;
  dateCreated: string;
}
export interface LeadsInitialState {
  authPending: boolean;
  isAuth: boolean;
  loginError: string | null;
  registerError: string | null;
  meInfo: IMeInfo | null;
}

const initialState: LeadsInitialState = {
  authPending: false,
  isAuth: false,
  meInfo: null,
  loginError: null,
  registerError: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setMeInfo(state, action: PayloadAction<IMeInfo | null>) {
      state.meInfo = action.payload;
    },
    setAuthPending(state, action: PayloadAction<boolean>) {
      state.authPending = action.payload;
    },
    setIsAuth(state, action: PayloadAction<boolean>) {
      state.isAuth = action.payload;
    },
    setLoginError(state, action: PayloadAction<string | null>) {
      state.loginError = action.payload;
    },
    setRegisterError(state, action: PayloadAction<string | null>) {
      state.registerError = action.payload;
    },
    updateMeInfo(state, action: PayloadAction<IMeInfo | null>) {
      state.meInfo = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setLoginError, setRegisterError, setMeInfo } = slice.actions;

// ---------------Util---------------------------------

export const successRegisterHandler =
  (responseData: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  dispatch => {
    const endTime = moment().add(responseData.expires_in, 'seconds').toString();
    const token = responseData.access_token;
    localStorage.setItem('access_token', token);
    localStorage.setItem('access_token_expires_in', endTime);
    dispatch(slice.actions.setIsAuth(true));
  };

// ---------------Country---------------------------------

export const userRegister =
  (dataObj: IAuthApiRegistration): ThunkAction<any, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(slice.actions.setAuthPending(true));
      const response = await authApi.registration(dataObj);
      if (response.status === 200) {
        const isBeingError = getState().auth.registerError;
        if (isBeingError) dispatch(slice.actions.setRegisterError(null));
        return response.data;
      } else throw new Error(response.message || 'Registration request error');
    } catch (error) {
      if (error instanceof Error) {
        dispatch(slice.actions.setRegisterError(error.message));
      }
    } finally {
      dispatch(slice.actions.setAuthPending(false));
    }
  };

export const userLogin =
  (name: string, pass: string): ThunkAction<any, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(slice.actions.setAuthPending(true));
      const response = await authApi.login(name, pass);
      if (response.status === 200 && response.data.access_token) {
        dispatch(successRegisterHandler(response.data));
        const isBeingError = getState().auth.loginError;
        if (isBeingError) dispatch(slice.actions.setLoginError(null));
      } else throw new Error(response.message || 'Auth request error');
    } catch (error) {
      if (error instanceof Error) {
        dispatch(slice.actions.setLoginError(error.message));
      }
    } finally {
      dispatch(slice.actions.setAuthPending(false));
    }
  };

export const updateMyInfo =
  (id: string, data: IUpdateUserInfo): ThunkAction<void, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      const response = await usersApi.updateSelfUser(id, data);
      if (response.status === 200) {
        dispatch(slice.actions.updateMeInfo(response.data));
      } else throw new Error(response.data.message || 'User update error');
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
      }
    }
  };

export const getMeInfo =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      const response = await usersApi.getMeInfo(id);

      if (response.status === 200) {
        dispatch(slice.actions.setMeInfo(response.data));
      } else throw new Error(response.data.message || 'Users request error');
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
      }
    }
  };
