import axios from 'axios';
import {headers} from './headers';

export class CampaignApi {
  static getAll = ({id, grpId}: {id: string; grpId: string}) => {
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/api/campaign?campaigngroup=${grpId}`,
      headers: {...headers(), 'ad-account-id': id},
    });
  };

  static create = ({id, data}: {id: string; data: any}) => {
    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}/api/campaign/schedule/${data.id}`,
      data,
      headers: {...headers(), 'ad-account-id': id},
    });
  };

  static updateStatus = ({id, status}: {id: string; status: any}) => {
    return axios({
      method: 'patch',
      url: `${process.env.REACT_APP_BACKEND_URL}/api/campaign/${id}`,
      data: {
        status,
      },
      headers: {...headers()},
    });
  };
}
