import { FC, useState } from 'react';
import { HomeLayout } from '../../components/HomeLayout';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import styles from './styles.module.scss';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../hooks/reduxHooks';
import logoImg from './../../assets/img/logo.png';

export const WelcomPage: FC = () => {
  const { isAuth } = useAppSelector(state => state.auth);

  var [activeTabId, setActiveTabId] = useState(0);
  const history = useHistory();

  useEffect(() => {
    if (isAuth) history.push('/linkedinAuth');
  }, [history, isAuth]);

  return (
    <HomeLayout>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <img src={logoImg} alt='' />
          <h1>inflows</h1>
        </div>
        <div className={styles.formContainer}>
          <div className={styles.form}>
            <Tabs
              value={activeTabId}
              onChange={(e, id) => setActiveTabId(id)}
              indicatorColor='primary'
              textColor='primary'
              centered>
              <Tab label='Login' classes={{ root: styles.tab, selected: styles.selected }} />
              <Tab label='New User' classes={{ root: styles.tab, selected: styles.selected }} />
            </Tabs>
            {activeTabId === 0 && <LoginForm />}
            {activeTabId === 1 && <RegisterForm />}
          </div>
        </div>
      </div>
    </HomeLayout>
  );
};

export default WelcomPage;
