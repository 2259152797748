import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import Pagination from 'react-bootstrap/Pagination';
import {itemPerPage} from '../pages/Leads';
import {shouldSkip, stoperFunc} from '../redux/slices/leads';

interface Props {
  dataLength: number | null;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  isFetchingDataFromHubspot?: boolean;
}

const Paginator: React.FC<Props> = ({dataLength, currentPage, setCurrentPage, isFetchingDataFromHubspot}) => {
  const [pageCount, setPageCount] = useState(0);
  const maxPageCount = 9;

  useEffect(() => {
    if (dataLength && dataLength > 0) {
      const pagesCount = Math.ceil(dataLength / itemPerPage);
      setPageCount(pagesCount);
    }
  }, [dataLength]);

  const showPaginationNumbers = () => {
    let pageArray = [];

    if (pageCount) {
      let endPage;
      let startPage;

      if (pageCount <= maxPageCount) {
        startPage = 1;
        endPage = pageCount;
      } else {
        const pagesBeforeAfter = Math.floor(maxPageCount / 2);
        startPage = currentPage - pagesBeforeAfter;
        endPage = currentPage + pagesBeforeAfter;

        if (endPage >= pageCount) {
          startPage = pageCount - maxPageCount + 1;
          endPage = pageCount;
        }

        if (startPage <= 1) {
          startPage = 1;
          endPage = maxPageCount - 1;
        }
      }
      for (let i = startPage; i <= endPage; i++) {
        pageArray.push(i);
      }
      return pageArray;
    }
  };

  const showingPages = showPaginationNumbers();

  // const stopBeforeRequest = () => {
  //   if (!shouldSkip && isFetchingDataFromHubspot) {
  //     stoperFunc(true);
  //   }
  // };

  return (
    <Pagination>
      <Pagination.First
        onClick={() => {
          // stopBeforeRequest();
          setCurrentPage(1);
        }}
      />
      <Pagination.Prev
        onClick={() => {
          if (currentPage > 1) {
            // stopBeforeRequest();
            setCurrentPage(currentPage - 1);
          }
        }}
      />
      {showingPages?.map((item, index, array) => {
        return (
          <Pagination.Item
            key={item}
            onClick={() => {
              // stopBeforeRequest();
              setCurrentPage(item);
            }}
            active={item === currentPage}
          >
            {item}
          </Pagination.Item>
        );
      })}
      <Pagination.Next
        onClick={() => {
          if (currentPage < pageCount) {
            // stopBeforeRequest();
            setCurrentPage(currentPage + 1);
          }
        }}
      />
      <Pagination.Last
        onClick={() => {
          // stopBeforeRequest();
          setCurrentPage(pageCount);
        }}
      />
    </Pagination>
  );
};

export default Paginator;
