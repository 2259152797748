import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Card, Dropdown, ListGroup, Offcanvas, Table, Button } from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2';
import { Layout } from '../../components/DashboardLayout';
import { SelectedAdAccountIdContext } from '../../context/SelectedAdAccount';
import { SelectedCampaignGroupContext } from '../../context/SelectedCampaignGroup';
import { CampaignApi } from '../../services/CampaignApi';
import { toast } from 'react-toastify';
import { CreativesApi } from '../../services/CreativesApi';
import { useHistory } from 'react-router-dom';
import { setCompaignsListForDropDrown, setSelectedCompaign } from '../../redux/slices/leads';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import EditCampaign from './EditCampaign';
import { isActiveSubscription } from '../../common/activeSubscriptionCheck';

interface Props {}

export const Dashboard = (props: Props) => {
  const [update, setUpdate] = useState(false);
  const [campaigns, setCampaigns] = useState<any>([]);
  const { selectedAdAccountId } = useContext(SelectedAdAccountIdContext);
  const { selectedCampaignGroupId, selectedCampaignGroupName } = useContext(
    SelectedCampaignGroupContext
  );
  const [selectedCampaign, setSelectedCampaign] = useState<number | null>(null);
  const [creatives, setCreatives] = useState<any>([]);
  const [show, setShow] = useState(false);
  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showModal, setShowModal] = useState(false);
  const { meInfo } = useAppSelector(state => state.auth);

  const onShowModalHandle = (isShow: boolean) => () => {
    setShowModal(isShow);
  };

  const UpdateValues = () => setUpdate(prev => !prev);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (campaigns.length !== 0 && selectedCampaign !== null) {
      CreativesApi.getAll({ id: campaigns[selectedCampaign].id }).then((res: any) =>
        setCreatives(res.data.creatives)
      );
    }
  }, [campaigns, selectedCampaign]);

  useEffect(() => {
    if (selectedCampaignGroupId && selectedAdAccountId) {
      CampaignApi.getAll({
        id: selectedAdAccountId || '',
        grpId: selectedCampaignGroupId || '',
      }).then((res: any) => {
        const compaigns = res.data.campaigns.elements;
        setCampaigns(compaigns);
        if (compaigns && compaigns.length !== 0) {
          setSelectedCampaign(0);
        }

        ///save compaigns list for "Leads" page
        const compaignSmart =
          compaigns &&
          compaigns.map((item: any): { name: string; id: string } => ({
            name: item.name,
            id: item.id,
          }));
        if (compaignSmart) dispatch(setCompaignsListForDropDrown(compaignSmart));
      });
    } else setCampaigns([]);
  }, [selectedCampaignGroupId, selectedAdAccountId, update, dispatch]);

  useEffect(() => {
    setSelectedCampaign(null);
  }, [selectedCampaignGroupId]);

  const onCompanyImpressionsHandler = (campaign: any) => (e: any) => {
    e.stopPropagation();
    if (meInfo && isActiveSubscription(meInfo)) {
      dispatch(setSelectedCompaign(campaign));
      history.push(`/dash/campaigns/${campaign.id}`);
    }
    // if (meInfo?.status === 'action') {
    //   window.location.href = `https://app.hubspot.com/oauth/authorize?client_id=1356caea-cfab-4996-8273-b1ff404909d7&scope=crm.objects.contacts.read%20crm.objects.companies.read%20crm.objects.deals.read&redirect_uri=${process.env.REACT_APP_BACKEND_URL}/api/hubspot/auth`;
    //   return;
    // }
    else {
      Swal.fire({
        title: 'Upgrade to Inflows Premium',
        showCancelButton: true,
        confirmButtonText: 'Subscribe',
        icon: 'warning',
        confirmButtonColor: '#2137f9',
      }).then(async result => {
        if (result.isConfirmed) {
          history.push(`/dash/settings/subscription`);
        }
      });
    }
  };

  const columnSize = useMemo(() => {
    return window.innerWidth > 1500
      ? ['20%', '9%', '9%', '10%', '20%', '12%', '11%']
      : ['20%', '9%', '9%', '5%', '15%', '10%', '15%'];
  }, []);

  return (
    <Layout UpdateValues={UpdateValues} title={selectedCampaignGroupName}>
      <div className='p-4'>
        <Table striped bordered hover>
          <colgroup>
            <col style={{ width: columnSize[0] }} />
            <col style={{ width: columnSize[1] }} />
            <col style={{ width: columnSize[2] }} />
            <col style={{ width: columnSize[3] }} />
            <col style={{ width: columnSize[4] }} />
            <col style={{ width: columnSize[5] }} />
            <col style={{ width: columnSize[6] }} />
          </colgroup>
          <thead>
            <tr>
              <th>Campaign Name</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Dayparting</th>
              <th>Days</th>
              {/* <th>Devices</th> */}
              <th>Campaign Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {campaigns.map((c: any, index: number) => (
                <tr
                    key={c.id}
                    onClick={e => {
                      e.stopPropagation();
                      handleShow();
                      setSelectedCampaign(index);
                    }}>
                  <td>{c.name}</td>
                  <td>
                    {/*{c.runSchedule.start ? moment(c.runSchedule.start, 'x').format('DD MMM YYYY') : '-'}{' '}*/}
                    {c.startTime ? moment(c.startTime).format('hh:mm a') : '-'}
                  </td>
                  <td>
                    {/*{c.runSchedule.end ? moment(c.runSchedule.end, 'x').format('DD MMM YYYY') : '-'}{' '}*/}
                    {c.endTime ? moment(c.endTime).format('hh:mm a') : '-'}
                  </td>
                  {/*<td>{c.daysOfWeek?.length ? 'On' : 'Off'}</td>*/}
                  <td>Off</td>
                  {/*<td>*/}
                  {/*  {c.daysOfWeek*/}
                  {/*      ? c.daysOfWeek.map((day: number) => {*/}
                  {/*        return `${*/}
                  {/*            [*/}
                  {/*              {index: 0, label: 'Sunday'},*/}
                  {/*              {index: 1, label: 'Monday'},*/}
                  {/*              {index: 2, label: 'Tuesday'},*/}
                  {/*              {index: 3, label: 'Wednesday'},*/}
                  {/*              {index: 4, label: 'Thursday'},*/}
                  {/*              {index: 5, label: 'Friday'},*/}
                  {/*              {index: 6, label: 'Saturday'},*/}
                  {/*            ][day].label*/}
                  {/*        }, `;*/}
                  {/*      })*/}
                  {/*      : '-'}*/}
                  {/*</td>*/}
                  <td>-</td>
                  {/* <td>
                  {c.devices
                    ? c.devices.length > 0
                      ? c.devices.map((device: string) => {
                          return `${device}, `;
                        })
                      : "android, linux,mac, windows"
                    : "android, linux,mac, windows"}
                </td> */}
                  <td>
                    {c.status === 'COMPLETED' || c.status === 'DRAFT' ? (
                        <Button disabled>{c.status}</Button>
                    ) : (
                        <Button disabled>PAUSED</Button>
                        // <>
                        //   <Dropdown onClick={e => e.stopPropagation()}>
                        //     <Dropdown.Toggle variant='success'>
                        //       {c.isActive || c.isActive ? 'ACTIVE' : c.status}
                        //     </Dropdown.Toggle>
                        //
                        //     <Dropdown.Menu>
                        //       <Dropdown.Item
                        //           onClick={() => {
                        //             const id = toast.loading('Updating status');
                        //             CampaignApi.updateStatus({
                        //               id: c.id,
                        //               status: 'ACTIVE',
                        //             }).then(res => {
                        //               toast.update(id, {
                        //                 render: 'Updated',
                        //                 type: 'success',
                        //                 isLoading: false,
                        //                 autoClose: 4000,
                        //               });
                        //               UpdateValues();
                        //             });
                        //           }}>
                        //         ACTIVE
                        //       </Dropdown.Item>
                        //       {/* <Dropdown.Item
                        //   onClick={() =>
                        //     CampaignApi.updateStatus({
                        //       id: c.id,
                        //       status: "DRAFT",
                        //     }).then((res) => UpdateValues())
                        //   }
                        // >
                        //   DRAFT
                        // </Dropdown.Item> */}
                        //       <Dropdown.Item
                        //           onClick={() => {
                        //             const id = toast.loading('Updating status');
                        //             CampaignApi.updateStatus({
                        //               id: c.id,
                        //               status: 'PAUSED',
                        //             }).then(res => {
                        //               toast.update(id, {
                        //                 render: 'Updated',
                        //                 type: 'success',
                        //                 isLoading: false,
                        //                 autoClose: 4000,
                        //               });
                        //               UpdateValues();
                        //             });
                        //           }}>
                        //         PAUSED
                        //       </Dropdown.Item>
                        //     </Dropdown.Menu>
                        //   </Dropdown>
                        // </>
                    )}
                    {/*{c.status === 'ACTIVE' && (*/}
                    {/*    <p className='text-muted h6'>*/}
                    {/*      <small>Your Ad is still active, would you like to pause?</small>*/}
                    {/*    </p>*/}
                    {/*)}*/}
                  </td>
                  <td className=''>
                    <div className='d-flex align-items-center'>
                      <Tooltip
                          title={'Dayparting'}
                          disableInteractive={c.status === 'COMPLETED' || c.status === 'DRAFT'}>
                      <span>
                        <Button
                            onClick={e => {
                              setSelectedCampaign(index);
                              onShowModalHandle(true)();
                              e.stopPropagation();
                            }}
                            disabled={true}
                            // disabled={c.status === 'COMPLETED' || c.status === 'DRAFT'}
                        >
                          <i className='bi bi-pencil-square'></i>
                        </Button>
                      </span>
                      </Tooltip>

                      <Tooltip title={'Manage Campaign'}>
                        <Button
                            onClick={e => {
                              e.stopPropagation();
                            }}
                            className='ms-2'
                            as='a'
                            href={`https://www.linkedin.com/campaignmanager/accounts/${selectedAdAccountId}/campaigns/${c.id}/details`}
                            target='__blank'>
                          <i className='bi bi-linkedin'></i>
                        </Button>
                      </Tooltip>

                      <Tooltip title={'Company Impressions'}>
                        <Button onClick={onCompanyImpressionsHandler(c)} className='ms-2'>
                          <i className='bi bi-bar-chart-fill'></i>
                        </Button>
                      </Tooltip>
                    </div>
                    {c.status === 'COMPLETED' ||
                        (c.status === 'DRAFT' ? (
                            <p className='text-muted h6'>
                              <small>Please launch / relaunch campaign from linkedin</small>
                            </p>
                        ) : !c.runSchedule.end ? (
                            <p className='text-muted h6'>
                              <small>No end date set</small>
                            </p>
                        ) : null)}
                  </td>
                </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {campaigns.length !== 0 && selectedCampaign !== null && (
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{campaigns[selectedCampaign].name}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ListGroup>
              <ListGroup.Item>Cost Type : {campaigns[selectedCampaign].costType}</ListGroup.Item>
              <ListGroup.Item>Type : {campaigns[selectedCampaign].format}</ListGroup.Item>
              <ListGroup.Item>
                Start Time:{' '}
                {campaigns[selectedCampaign].runSchedule.start
                  ? moment(campaigns[selectedCampaign].runSchedule.start, 'x').format('DD MMM YYYY')
                  : '-'}{' '}
                {campaigns[selectedCampaign].startTime
                  ? moment(campaigns[selectedCampaign].startTime).format('hh:mm a')
                  : '-'}
              </ListGroup.Item>
              <ListGroup.Item>
                End Time:{' '}
                {campaigns[selectedCampaign].runSchedule.end
                  ? moment(campaigns[selectedCampaign].runSchedule.end, 'x').format('DD MMM YYYY')
                  : '-'}{' '}
                {campaigns[selectedCampaign].endTime
                  ? moment(campaigns[selectedCampaign].endTime).format('hh:mm a')
                  : '-'}
              </ListGroup.Item>
              <ListGroup.Item>
                Days:{' '}
                {campaigns[selectedCampaign].daysOfWeek
                  ? campaigns[selectedCampaign].daysOfWeek.map((day: number) => {
                      return `${
                        [
                          { index: 0, label: 'Sunday' },
                          { index: 1, label: 'Monday' },
                          { index: 2, label: 'Tuesday' },
                          { index: 3, label: 'Wednesday' },
                          { index: 4, label: 'Thursday' },
                          { index: 5, label: 'Friday' },
                          { index: 6, label: 'Saturday' },
                        ][day].label
                      }, `;
                    })
                  : '-'}
              </ListGroup.Item>
              {/* <ListGroup.Item>
                Devices:{" "}
                {campaigns[selectedCampaign].devices
                  ? campaigns[selectedCampaign].devices.length > 0
                    ? campaigns[selectedCampaign].devices.map(
                        (device: string) => {
                          return `${device}, `;
                        }
                      )
                    : "android, linux,mac, windows"
                  : "android, linux,mac, windows"}
                {" "}
              </ListGroup.Item> */}
              <ListGroup.Item>Campaign Status: {campaigns[selectedCampaign].status}</ListGroup.Item>
            </ListGroup>
            <ListGroup className='mt-4'>
              <h4>
                Creatives
                <a
                  className='ms-2'
                  href={`https://www.linkedin.com/campaignmanager/accounts/${selectedAdAccountId}/campaigns/${campaigns[selectedCampaign].id}/creatives`}
                  target='__blank'>
                  <Button variant='success'>
                    {' '}
                    <i className='bi bi-plus-lg me-1'></i>Creative
                  </Button>
                </a>
              </h4>
              {creatives.map((c: any) => {
                if (campaigns[selectedCampaign].format === 'TEXT_AD') {
                  return (
                    <Card style={{ width: '100%' }} key={c.id}>
                      {/* <Card.Img variant='top' src='holder.js/100px180' /> */}
                      <Card.Body>
                        <Card.Title>
                          {c.variables.data['com.linkedin.ads.TextAdCreativeVariables'].title}
                        </Card.Title>
                        <Card.Text>
                          {c.variables.data['com.linkedin.ads.TextAdCreativeVariables'].title}
                        </Card.Text>
                        {/* <Button variant='primary'>Go somewhere</Button> */}
                      </Card.Body>
                    </Card>
                  );
                }
                return null;
              })}
            </ListGroup>
          </Offcanvas.Body>
        </Offcanvas>
      )}
      {campaigns.length !== 0 && selectedCampaign !== null && (
        <EditCampaign
          campaigns={campaigns}
          selectedCampaign={selectedCampaign}
          onShowModalHandle={onShowModalHandle}
          showModal={showModal}
          handleClose={handleClose}
          UpdateValues={UpdateValues}
        />
      )}
    </Layout>
  );
};
