import React, { useState, memo, FC, FormEvent } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2';
import { authApi } from '../../api/authApi';
import styles from './styles.module.scss';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const ResetPasswordModal: FC<IProps> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [btnLoading, setBtnLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault();
      setBtnLoading(true);
      const response = await authApi.forgotPassword({ email });
      if (response.status !== 200) {
        throw '';
      }
      Swal.fire({
        title: 'Success!',
        text: 'Forget password link successfully sent to your email!',
        icon: 'success',
        confirmButtonColor: '#2137f9',
      });
    } catch (err: any) {
      Swal.fire({
        title: 'Error!',
        text: err?.message ? err.message : 'Invalid Email/Password',
        icon: 'error',
        confirmButtonColor: '#2137f9',
      });
    } finally {
      setBtnLoading(false);
      onClose();
    }
  };

  const onSetEmailHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Reset Your Password</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent style={{ overflowX: 'hidden' }}>
          <TextField
            onInput={onSetEmailHandler}
            style={{ width: '100%', margin: '4px' }}
            variant='standard'
            type='email'
            label='Enter Email'
            value={email}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={onClose} className={styles.submitButton}>
            Cancel
          </Button>
          <Button
            color='primary'
            type='submit'
            disabled={btnLoading}
            className={styles.submitButton}>
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default memo(ResetPasswordModal);
