import React, { useContext, useEffect, useState } from 'react';
import { Button, Dropdown, FloatingLabel, Form, Modal, Nav } from 'react-bootstrap';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SelectedAdAccountIdContext } from '../context/SelectedAdAccount';
import { SelectedCampaignGroupContext } from '../context/SelectedCampaignGroup';
import { AdAccountApi } from '../services/AdAccountApi';
import { CampaignGroupsApi } from '../services/CampaignGroupsApi';
import '../styles/Dashboard.css';
import logoImg from '../assets/img/logo.png';

export const LeftSidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [adAccounts, setAdAccounts] = useState<any>([]);
  const [campaignGroups, setCampaignGroups] = useState<any>([]);
  const [newAccountModal, setNewAccountModal] = useState(false);
  const openNewAccountModal = () => setNewAccountModal(true);
  const closeNewAccountModal = () => setNewAccountModal(false);
  const [newCampaignGroupModal, setNewCampaignGroupModal] = useState(false);
  // const openNewCampaignGroupModal = () => setNewCampaignGroupModal(true);
  const closeNewCampaignGroupModal = () => setNewCampaignGroupModal(false);
  const [update, setUpdate] = useState(false);
  const updateValues = () => setUpdate(prev => !prev);
  const userData = JSON.parse(localStorage.getItem('userData') || '');

  const history = useHistory();
  const location = useLocation();

  const { selectedAdAccountId, setSelectedAdAccountId } = useContext(SelectedAdAccountIdContext);
  const { selectedCampaignGroupId, setSelectedCampaignGroupId, setSelectedCampaignGroupName } =
    useContext(SelectedCampaignGroupContext);

  useEffect(() => {
    AdAccountApi.getAllAccounts().then((res: any) => {
      setAdAccounts(res.data.adAccounts);
    });
  }, [update]);

  useEffect(() => {
    if (adAccounts && adAccounts.length > 0 && !selectedAdAccountId) {
      setSelectedAdAccountId(adAccounts[0].account.split('sponsoredAccount:')[1]);
      localStorage.removeItem('selectedCampaignGroupId');
      setSelectedCampaignGroupId(null);
      setSelectedCampaignGroupName(null);
    }
  }, [
    adAccounts,
    selectedAdAccountId,
    setSelectedAdAccountId,
    setSelectedCampaignGroupId,
    setSelectedCampaignGroupName,
  ]);

  useEffect(() => {
    if (selectedAdAccountId) {
      CampaignGroupsApi.getAllGroups({ id: selectedAdAccountId || '' }).then((res: any) => {
        setCampaignGroups(res.data.campaignGroups.elements);
      });
    }
  }, [selectedAdAccountId, update]);

  useEffect(() => {
    if (campaignGroups && campaignGroups.length > 0 && !selectedCampaignGroupId) {
      if (campaignGroups[0].account.split('sponsoredAccount:')[1] === selectedAdAccountId) {
        setSelectedCampaignGroupId(campaignGroups[0].id);
        setSelectedCampaignGroupName(campaignGroups[0].name);
      }
    }
  }, [
    campaignGroups,
    selectedAdAccountId,
    selectedCampaignGroupId,
    setSelectedCampaignGroupId,
    setSelectedCampaignGroupName,
  ]);

  const adAccountSelect = (account: any) => {
    setSelectedAdAccountId(account.account.split('sponsoredAccount:')[1]);
    localStorage.removeItem('selectedCampaignGroupId');
    setSelectedCampaignGroupId(null);
    setSelectedCampaignGroupName(null);
    history.push('/dash/campaigns');
  };

  if (sidebarOpen) {
    return (
      <div
        id='sidebar-wrapper'
        className='d-flex flex-column flex-shrink-0 p-3 text-white bg-dark leftSidebar'>
        {/* <CloseButton aria-label='Hide' onClick={() => setSidebarOpen(false)} /> */}
        <div>
          <div className='d-flex justify-content-center mt-2'>
            <img src={logoImg} alt='LOGO' />
          </div>
          <Nav
            className='col-md-12 d-flex flex-column mt-5 mb-4 border-bottom px-0 pb-4 mainMenu'
            variant='pills'
            activeKey='/home'>
            <Nav.Item className={`no-style-link text-white`}>
              <NavLink
                to='/dash/campaigns'
                className='p-2 w-100 text-white h5 text-decoration-none rounded d-inline-block'>
                Campaign group
              </NavLink>
            </Nav.Item>
            {location.pathname.includes('/dash/campaigns') &&
              campaignGroups &&
              campaignGroups.map((grp: any) => (
                <Nav.Item
                  style={{ cursor: 'pointer' }}
                  className={`p-2 ps-4 no-style-link text-white ${
                    selectedCampaignGroupId == grp.id && 'bg-primary rounded'
                  }`}
                  key={grp.id}
                  onClick={() => {
                    history.push('/dash/campaigns');
                    setSelectedCampaignGroupId(grp.id);
                    setSelectedCampaignGroupName(grp.name);
                  }}>
                  {grp.name}
                </Nav.Item>
              ))}
            <Nav.Item className={`mt-2 no-style-link text-white inline-block`}>
              <NavLink
                to='/dash/settings'
                className='p-2 w-100 text-white h5 text-decoration-none rounded d-inline-block'>
                Settings
              </NavLink>
            </Nav.Item>
            <Nav.Item className={`mt-2 no-style-link text-white inline-block`}>
              <a
                href='https://inflows.app/tutorials/'
                target='blank'
                className='p-2 w-100 text-white h5 text-decoration-none rounded d-inline-block'>
                Tutorials
              </a>
            </Nav.Item>
          </Nav>
        </div>

        <Dropdown drop='up'>
          <Dropdown.Toggle
            variant='success'
            id='dropdown-basic'
            className='d-flex align-items-center text-white text-decoration-none dropdown-toggle bg-transparent border-0'>
            <strong>
              {userData.firstName} {userData.lastName}
            </strong>
          </Dropdown.Toggle>

          <Dropdown.Menu className='addAccounDropdown'>
            {adAccounts.map((account: any) => (
              <Dropdown.Item
                className={
                  account.account.split('sponsoredAccount:')[1] === selectedAdAccountId
                    ? 'bg-primary text-white'
                    : ''
                }
                key={account.account}
                onClick={() => adAccountSelect(account)}>
                {account.name || '-'}
              </Dropdown.Item>
            ))}
            <Dropdown.Item className='bg-success text-white' onClick={openNewAccountModal}>
              <i className='bi bi-plus-lg me-1'></i> Ad Account
            </Dropdown.Item>
            <Dropdown.Item
              className='bg-danger text-white'
              onClick={() => {
                localStorage.removeItem('linkedin-access-code');
                localStorage.removeItem('userData');
                localStorage.removeItem('loginDetails');

                localStorage.removeItem('access_token');
                localStorage.removeItem('access_token_expires_in');

                history.push('/');
              }}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Modal show={newCampaignGroupModal} onHide={closeNewCampaignGroupModal}>
          <Form
            method='POST'
            onSubmit={e => {
              e.preventDefault();
              const toastId = toast.loading('Creating new group');
              const formData = new FormData(e.target as HTMLFormElement);
              const formDataObj = Object.fromEntries(formData.entries());
              const date = new Date();

              CampaignGroupsApi.createGroup({
                data: {
                  ...formDataObj,
                  runSchedule: {
                    start: date.getTime(),
                  },
                },
                id: selectedAdAccountId || '',
              })
                .then(res => {
                  updateValues();
                  closeNewCampaignGroupModal();
                  toast.update(toastId, {
                    render: 'Success',
                    type: 'success',
                    isLoading: false,
                    autoClose: 2000,
                  });
                })
                .catch(rej => {
                  toast.update(toastId, {
                    render: 'Error',
                    type: 'error',
                    isLoading: false,
                    autoClose: 2000,
                  });
                });
            }}>
            <Modal.Header closeButton>
              <Modal.Title>Create campaign group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className='mb-3'>
                <Form.Label>Campaign group name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter new group name'
                  name='name'
                  minLength={4}
                />
              </Form.Group>
              <FloatingLabel label='Status'>
                <Form.Select aria-label='Status' name='status'>
                  <option value='ACTIVE'>Active</option>
                  {/* <option value='DRAFT'>Draft</option> */}
                </Form.Select>
              </FloatingLabel>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={closeNewCampaignGroupModal}>
                Close
              </Button>
              <Button variant='primary' type='submit'>
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal show={newAccountModal} onHide={closeNewAccountModal}>
          <Form
            onSubmit={e => {
              e.preventDefault();
              const toastId = toast.loading('Creating new account');
              const formData = new FormData(e.target as HTMLFormElement);
              const formDataObj = Object.fromEntries(formData.entries());

              AdAccountApi.create(formDataObj)
                .then(res => {
                  updateValues();
                  closeNewAccountModal();
                  toast.update(toastId, {
                    render: 'Success',
                    type: 'success',
                    isLoading: false,
                    autoClose: 2000,
                  });
                })
                .catch(rej => {
                  toast.update(toastId, {
                    render: 'Error occured',
                    type: 'error',
                    isLoading: false,
                    autoClose: 2000,
                  });
                });
            }}>
            <Modal.Header closeButton>
              <Modal.Title>Create account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className='mb-3'>
                <Form.Label>Account name</Form.Label>
                <Form.Control type='text' placeholder='Enter new account name' name='companyName' />
              </Form.Group>
              <FloatingLabel label='Note that you cannot change currency later.'>
                <Form.Select aria-label='Select currency' name='currencyCode'>
                  <option>Select currency</option>
                  <option value='USD'>USD</option>
                </Form.Select>
              </FloatingLabel>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={closeNewAccountModal}>
                Close
              </Button>
              <Button variant='primary' type='submit'>
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
  return (
    <div className='p-1' onClick={() => setSidebarOpen(true)}>
      Opn
    </div>
  );
};
