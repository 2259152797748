import {ISortBy} from '../pages/Settings/UsersSettings';

export const sortData = (data: any[], sortBy: ISortBy | null) => {
  if (data && sortBy)
    return data.sort((a, b) => {
      let first = a[sortBy.name];
      let second = b[sortBy.name];

      // if (sortBy.by === 'status') {
      //   const aError = a.status && a.status.find(i => i.level === 'ERROR');
      //   const aWarn = a.status && a.status.find(i => i.level === 'WARN');
      //   const aInfo = a.status && a.status.find(i => i.level === 'INFO');

      //   const bError = b.status && b.status.find(i => i.level === 'ERROR');
      //   const bWarn = b.status && b.status.find(i => i.level === 'WARN');
      //   const bInfo = b.status && b.status.find(i => i.level === 'INFO');

      //   first = a.status ? (aError && aError.level) || (aWarn && aWarn.level) || (aInfo && aInfo.level) : '';
      //   second = b.status ? (bError && bError.level) || (bWarn && bWarn.level) || (bInfo && bInfo.level) : '';
      // }

      // if (sortBy.by === 'report-time') {
      //   first = new Date(a[sortBy.by]);
      //   second = new Date(b[sortBy.by]);
      // }
      // if (sortBy.name === 'monthlySpend') {
      //   first = a[sortBy.name];
      //   second = b[sortBy.name];
      // }
      if (sortBy.sortParam === 'asc') {
        if (first > second) {
          return 1;
        }
        if (first < second) {
          return -1;
        }
        return 0;
      } else if (sortBy.sortParam === 'dec') {
        if (first > second) {
          return -1;
        }
        if (first < second) {
          return 1;
        }
      }
      return 0;
    });
  else return data;
};
