import React from 'react';
import {createContext, useState} from 'react';

interface ISelectedCampaignGroup {
  selectedCampaignGroupId: string | null;
  setSelectedCampaignGroupId: (id: string | null) => void;
  selectedCampaignGroupName: string | null;
  setSelectedCampaignGroupName: (name: string | null) => void;
}

const defaultValue: ISelectedCampaignGroup = {
  selectedCampaignGroupId: null,
  setSelectedCampaignGroupId: () => undefined,
  selectedCampaignGroupName: null,
  setSelectedCampaignGroupName: () => undefined,
};

export const SelectedCampaignGroupContext = createContext(defaultValue);

export const SelectedCampaignGroupProvider: React.FC = ({children}) => {
  const [selectedCampaignGroupId, setSelectedCampaignGroupId] = useState<string | null>(
    localStorage.getItem('selectedCampaignGroupId') || null
  );
  const [selectedCampaignGroupName, setSelectedCampaignGroupName] = useState<string | null>(null);

  const SetId = (id: string | null) => {
    setSelectedCampaignGroupId(id);
    if (id) localStorage.setItem('selectedCampaignGroupId', id);
  };

  return (
    <SelectedCampaignGroupContext.Provider
      value={{
        selectedCampaignGroupId,
        setSelectedCampaignGroupId: SetId,
        selectedCampaignGroupName,
        setSelectedCampaignGroupName,
      }}
    >
      {children}
    </SelectedCampaignGroupContext.Provider>
  );
};
