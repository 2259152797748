import React, { FC, useMemo } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import {
  createSubscription,
  saveSubscriptionToBase,
  setIsPendingCreateSubscription,
} from '../../../redux/slices/payment';
import { getMeInfo } from '../../../redux/slices/auth';

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '18px',
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    []
  );

  return options;
};
interface IProps {
  priceId: string;
  setNewSubscriptionOpen: (param: boolean) => void;
}

const StripeForm: FC<IProps> = ({ priceId, setNewSubscriptionOpen }) => {
  const options = useOptions();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const { isPendingCreateSubscription } = useAppSelector(state => state.payment);
  const { meInfo } = useAppSelector(state => state.auth);

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    dispatch(setIsPendingCreateSubscription(true));
    let cardElement = elements.getElement(CardNumberElement)!;
    const paymentMethod = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (paymentMethod.hasOwnProperty('error')) {
      Swal.fire({
        title: paymentMethod && paymentMethod.error ? paymentMethod.error.message : 'Error',
        text: ``,
        icon: 'error',
      });
    } else if (paymentMethod) {
      const token = await stripe.createToken(cardElement);
      if (token && meInfo) {
        dispatch(
          createSubscription({
            userId: meInfo._id,
            paymentMethodId: paymentMethod!.paymentMethod!.id,
            priceId: priceId,
            token: token!.token!.id,
          })
        ).then((resolve: { client_secret: string; status: string; subscription: any }) => {
          const saveBase = () => {
            dispatch(saveSubscriptionToBase(resolve.subscription, meInfo._id)).then(
              (response: string) => {
                if (response !== 'success') {
                  Swal.fire({
                    title: 'Subscription saves failed',
                    text: `Please contact support`,
                    icon: 'error',
                  });
                } else {
                  Swal.fire({
                    title: 'Success!',
                    text: `Subscribed successfully`,
                    icon: 'success',
                  });
                  dispatch(getMeInfo(meInfo._id));
                }
              }
            );
          };

          if (resolve.status === 'succeeded') {
            saveBase();
          } else if (resolve.status === 'requires_action') {
            stripe.confirmCardPayment(resolve.client_secret).then(async function (result) {
              if (result.error) {
                Swal.fire({
                  title: result.error.message,
                  text: ``,
                  icon: 'error',
                });
                dispatch(setIsPendingCreateSubscription(false));
              } else {
                saveBase();
              }
            });
          }
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <div className='py-3'>
        <div style={{ marginBottom: 15 }}>
          <Typography variant='subtitle2' component='b'>
            Card number
          </Typography>
          <CardNumberElement options={options} />
        </div>
        <div style={{ marginBottom: 15 }}>
          <Typography variant='subtitle2' component='b'>
            Expiration date
          </Typography>
          <CardExpiryElement options={options} />
        </div>
        <div style={{ marginBottom: 15 }}>
          <Typography variant='subtitle2' component='b'>
            CVC
          </Typography>
          <CardCvcElement options={options} />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant='contained'
          size='small'
          style={{ margin: 10 }}
          onClick={() => setNewSubscriptionOpen(false)}>
          Close
        </Button>
        <LoadingButton
          loading={isPendingCreateSubscription}
          variant='contained'
          size='small'
          color='primary'
          style={{ margin: 10 }}
          type='submit'
          disabled={!stripe}>
          Submit
        </LoadingButton>
      </div>
    </form>
  );
};

export default StripeForm;
