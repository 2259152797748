import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ThunkAction } from 'redux-thunk';

import { AnyAction } from 'redux';
import { RootState } from '../store';

import { IUpdateUserInfo } from '../../interfaces/apiInterfaces';
import { paymentApi } from '../../api/paymentApi';
import { ISubscriptionObject } from '../../interfaces/paymentsInterfaces';
// ----------------------------------------------------------------------

export interface UsersInitialState {
  isPendingCreateSubscription: boolean;
  plans: any[];
  subscriptions: any[];
  paymentHistory: any[];
  paymentHistoryPending: boolean;
}

const initialState: UsersInitialState = {
  isPendingCreateSubscription: false,
  plans: [],
  subscriptions: [],
  paymentHistory: [],
  paymentHistoryPending: false,
};

const slice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setIsPendingCreateSubscription(state, action: PayloadAction<boolean>) {
      state.isPendingCreateSubscription = action.payload;
    },
    setPlans(state, action: PayloadAction<any[]>) {
      state.plans = action.payload;
    },
    setSubscriptions(state, action: PayloadAction<any[]>) {
      state.subscriptions = action.payload;
    },
    deleteSubscription(state, action: PayloadAction<string>) {
      state.subscriptions = state.subscriptions.filter(item => item.id !== action.payload);
    },
    addSubscription(state, action: PayloadAction<any>) {
      state.subscriptions = [...state.subscriptions, action.payload];
    },
    setPaymentHistory(state, action: PayloadAction<any[]>) {
      state.paymentHistory = action.payload;
    },
    setPaymentHistoryPending(state, action: PayloadAction<boolean>) {
      state.paymentHistoryPending = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setIsPendingCreateSubscription, setPaymentHistory } = slice.actions;

// ---------------Country---------------------------------

export const createSubscription =
  (obj: ISubscriptionObject): ThunkAction<any, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      const response = await paymentApi.createSubscription(obj);
      if (response.status === 200) {
        // if (response.data.status === 'succeeded') {
        //   dispatch(slice.actions.addSubscription(response.data.subscription));
        //   return response.data;
        // }
        return response.data;
        // return 'success';
      } else throw new Error(response.data.message || 'Users request error');
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
        // dispatch(slice.actions.setLoginError(error.message));
      }
    } finally {
      // dispatch(slice.actions.setIsPendingCreateSubscription(false));
    }
  };

export const saveSubscriptionToBase =
  (
    subscription: ISubscriptionObject,
    userId: string
  ): ThunkAction<any, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      const response = await paymentApi.saveSubscriptionToBase(subscription, userId);
      if (response.status === 200) {
        if (response.data.status === 'success') {
          dispatch(slice.actions.addSubscription(subscription));
          return 'success';
        }
      } else throw new Error(response.data.message || 'Subscription saves failed');
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
        // dispatch(slice.actions.setLoginError(error.message));
      }
    } finally {
      dispatch(slice.actions.setIsPendingCreateSubscription(false));
    }
  };

export const getAllPlans =
  (): ThunkAction<void, RootState, unknown, AnyAction> => async dispatch => {
    try {
      const response = await paymentApi.getPlans();
      if (response.status === 200) {
        dispatch(slice.actions.setPlans(response.data));
      } else throw new Error(response.data.message || 'Plans request error');
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
        // dispatch(slice.actions.setLoginError(error.message));
      }
    }
  };

export const getAllSubscriptions =
  (userId: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      const response = await paymentApi.getAllSubscriptions(userId);
      if (response.status === 200) {
        if (response.data.subscriptions.data && response.data.subscriptions.data.length > 0)
          dispatch(slice.actions.setSubscriptions(response.data.subscriptions.data));
      } else throw new Error(response.data.message || 'Plans request error');
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
        // dispatch(slice.actions.setLoginError(error.message));
      }
    }
  };

export const unsubscribe =
  (subscriptionId: string, userId: string): ThunkAction<any, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      const response = await paymentApi.unsubscribe(subscriptionId, userId);
      if (response.status === 200) {
        dispatch(slice.actions.deleteSubscription(response.data.confirmation.id));
        return 'success';
      } else throw new Error(response.data.message || 'Unsubscribe request error');
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
        return error.message;
      }
    }
  };

export const deleteSubscriptionFromBase =
  (userId: string): ThunkAction<any, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      const response = await paymentApi.deleteSubscription(userId);
      if (response.status === 200) {
        // dispatch(slice.actions.deleteSubscription(response.data.confirmation.id));
        return 'success';
      } else throw new Error(response.data.message || 'Delete subscription request error');
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
      }
    }
  };

export const getPaymentHistory =
  (userId: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      dispatch(slice.actions.setPaymentHistoryPending(true));
      const response = await paymentApi.getPaymentHistory(userId);
      if (response.status === 200) {
        if (response.data.paymentHistory && response.data.paymentHistory.length > 0)
          dispatch(slice.actions.setPaymentHistory(response.data.paymentHistory));
      } else throw new Error(response.data.message || 'Plans request error');
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
        // dispatch(slice.actions.setLoginError(error.message));
      }
    } finally {
      dispatch(slice.actions.setPaymentHistoryPending(false));
    }
  };
