import { IUpdateUserInfo } from '../interfaces/apiInterfaces';
import { axiosInstanceLogined } from './axiosInstance';

export const usersApi = {
  getAllUsers() {
    return axiosInstanceLogined
      .get(`/user`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response || 'Something went wrong';
      });
  },
  updateSelfUser(id: string, data: IUpdateUserInfo) {
    return axiosInstanceLogined
      .patch(`user/update/${id}`, data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response || 'Something went wrong';
      });
  },
  updateUser(id: string, data: IUpdateUserInfo) {
    return axiosInstanceLogined
      .patch(`user/${id}`, data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response || 'Something went wrong';
      });
  },
  getUserInfo(id: string) {
    return axiosInstanceLogined
      .get(`user/${id}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response || 'Something went wrong';
      });
  },
  getMeInfo(id: string) {
    return axiosInstanceLogined
      .get(`user/self/${id}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response || 'Something went wrong';
      });
  },
};
