import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';

export const HomeLayout: React.FC = ({ children }) => {
  return (
    <div>
      {/*<Navbar bg='primary' variant='dark' expand='lg'>*/}
      {/*  <Container>*/}
      {/*    /!*<Navbar.Brand href='/'>Session Media</Navbar.Brand>*!/*/}
      {/*    /!* <Navbar.Toggle aria-controls='basic-navbar-nav' />*/}
      {/*    <Navbar.Collapse id='basic-navbar-nav'>*/}
      {/*      <Nav className='me-auto'>*/}
      {/*        <Nav.Link href='#home'>Home</Nav.Link>*/}
      {/*        <Nav.Link href='#link'>Link</Nav.Link>*/}
      {/*      </Nav>*/}
      {/*    </Navbar.Collapse> *!/*/}
      {/*  </Container>*/}
      {/*</Navbar>*/}
      <div>{children}</div>
    </div>
  );
};
