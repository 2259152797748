import React from 'react';
import {createContext, useState} from 'react';

interface ISelectedAdAccountId {
  selectedAdAccountId: string | null;
  setSelectedAdAccountId: (id: string) => void;
}

const defaultValue: ISelectedAdAccountId = {
  selectedAdAccountId: null,
  setSelectedAdAccountId: () => undefined,
};

export const SelectedAdAccountIdContext = createContext(defaultValue);

export const SelectedAdAccountIdProvider: React.FC = ({children}) => {
  const [selectedAdAccountId, setSelectedAdAccountId] = useState<string | null>(
    localStorage.getItem('selectedAdAccountId') || null
  );
  const SetId = (id: string) => {
    setSelectedAdAccountId(id);
    localStorage.setItem('selectedAdAccountId', id);
  };
  return (
    <SelectedAdAccountIdContext.Provider value={{selectedAdAccountId, setSelectedAdAccountId: SetId}}>
      {children}
    </SelectedAdAccountIdContext.Provider>
  );
};
