import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ThunkAction } from 'redux-thunk';

import { AnyAction } from 'redux';
import { RootState } from '../store';

import { usersApi } from '../../api/usersApi';
import { IUpdateUserInfo } from '../../interfaces/apiInterfaces';
// ----------------------------------------------------------------------

export interface UsersInitialState {
  usersList: any[] | null;
}

const initialState: UsersInitialState = {
  usersList: null,
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsersList(state, action: PayloadAction<any[] | null>) {
      state.usersList = action.payload;
    },
    updateUser(state, action: PayloadAction<any>) {
      if (state.usersList)
        state.usersList = state.usersList?.map(item => {
          if (item._id === action.payload._id) return action.payload;
          return item;
        });
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// ---------------Country---------------------------------

export const getAllUsers =
  (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch, getState) => {
    try {
      const response = await usersApi.getAllUsers();
      if (response.status === 200) {
        dispatch(slice.actions.setUsersList(response.data));
      } else throw new Error(response.data.message || 'Users request error');
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
        // dispatch(slice.actions.setLoginError(error.message));
      }
    } finally {
      // dispatch(slice.actions.setAuthPending(false));
    }
  };

export const updateUserInfo =
  (id: string, data: IUpdateUserInfo): ThunkAction<void, RootState, unknown, AnyAction> =>
  async dispatch => {
    try {
      const response = await usersApi.updateUser(id, data);
      if (response.status === 200) {
        dispatch(slice.actions.updateUser(response.data));
      } else throw new Error(response.data.message || 'Auth request error');
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
        // dispatch(slice.actions.setLoginError(error.message));
      }
    } finally {
      // dispatch(slice.actions.setAuthPending(false));
    }
  };
