import React, { FC, useState } from 'react';
import { CircularProgress, Typography, Button, TextField, Fade } from '@mui/material';
import styles from './styles.module.scss';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { setLoginError, userLogin } from '../../redux/slices/auth';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import ResetPasswordModal from './ResetPasswordModal';
import Swal from 'sweetalert2';

export const LoginForm: FC = () => {
  var [loginValue, setLoginValue] = useState('');
  var [passwordValue, setPasswordValue] = useState('');

  const [isOpenResetPasswordModal, setResetPasswordModal] = useState(false);
  const { authPending, loginError } = useSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();

  const handleLogin = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(userLogin(loginValue, passwordValue));
  };

  useEffect(() => {
    return function () {
      if (loginError) dispatch(setLoginError(null));
    };
  }, [dispatch, loginError]);

  const onSetResetPasswordModal = (isOpen: boolean) => () => setResetPasswordModal(isOpen);

  return (
    <React.Fragment>
      <form onSubmit={handleLogin}>
        <Typography variant='h1' className={styles.greeting}>
          Welcome User
        </Typography>
        <Fade in={!!loginError}>
          <Typography color='error' className={styles.errorMessage}>
            {loginError || 'Something is wrong with your login or password :('}
          </Typography>
        </Fade>
        <TextField
          id='login'
          variant='standard'
          InputProps={{
            classes: {
              underline: styles.textFieldUnderline,
            },
          }}
          value={loginValue}
          onChange={e => setLoginValue(e.target.value)}
          margin='normal'
          placeholder='Enter name'
          type='text'
          fullWidth
          required
        />
        <TextField
          id='password'
          variant='standard'
          InputProps={{
            classes: {
              underline: styles.textFieldUnderline,
            },
          }}
          value={passwordValue}
          onChange={e => setPasswordValue(e.target.value)}
          margin='normal'
          placeholder='Enter Password'
          type='password'
          fullWidth
          required
        />
        <div className={styles.formButtons}>
          {authPending ? (
            <CircularProgress size={26} className={styles.loginLoader} />
          ) : (
            <Button
              disabled={loginValue.length === 0 || passwordValue.length === 0}
              variant='contained'
              color='primary'
              size='large'
              type='submit'
              className={styles.submitButton}>
              Login
            </Button>
          )}
          <Button
            color='primary'
            size='large'
            className={styles.forgetButton}
            onClick={onSetResetPasswordModal(true)}>
            Forgot Password
          </Button>
        </div>
      </form>
      <ResetPasswordModal
        isOpen={isOpenResetPasswordModal}
        onClose={onSetResetPasswordModal(false)}
      />
    </React.Fragment>
  );
};

export default LoginForm;
