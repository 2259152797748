import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Table, Form, Row, Col, Button } from 'react-bootstrap';
import { SelectedCampaignGroupContext } from '../../context/SelectedCampaignGroup';
import { useHistory, useParams } from 'react-router-dom';
import {
  getCompaniesFromHubSpot,
  getLeadsListByCampaign,
  setHubspotErrorRequest,
  setLeadsInfoFromHubspot,
  setLeadsList,
  setSelectedCompaign,
  shouldSkip,
  stoperFunc,
} from '../../redux/slices/leads';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { RootState } from '../../redux/store';
import { serachData } from '../../utils/serachData';
import Paginator from '../../components/Pagination';
import Spinner from 'react-bootstrap/Spinner';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import DateRangePickerComponent from '../../components/DateRangePicker';
import moment, { Moment } from 'moment';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import { SelectedAdAccountIdContext } from '../../context/SelectedAdAccount';

interface IParam {
  id: string;
}
export interface IDateRangeState {
  start: Moment;
  end: Moment;
}
//lead on page count
export const itemPerPage = 25;

export const Leads = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const {
    leadsList,
    selectedCompaign,
    compaignsListForDropDrown,
    hubspotDataList,
    isFetchingDataFromHubspot,
    isFetchingDataFromLinkedIn,
    hubspotErrorRequest,
  } = useSelector((state: RootState) => state.leads);
  const [currentPage, setCurrentPage] = useState(1);
  const { selectedCampaignGroupName } = useContext(SelectedCampaignGroupContext);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const params: IParam = useParams();
  const endDateInitial = moment();
  const startInitialDate = moment(endDateInitial).subtract(2, 'months');
  const [dateRange, setDateRange] = useState({ start: startInitialDate, end: endDateInitial });
  const { selectedAdAccountId } = useContext(SelectedAdAccountIdContext);

  const searchedLeads = useMemo(
    () => serachData(leadsList && leadsList.elements ? leadsList.elements : null, searchQuery),
    [leadsList, searchQuery]
  );

  useEffect(() => {
    if (params && params.id && selectedAdAccountId) {
      const daterange = {
        startyear: dateRange.start.format('YYYY'),
        startmonth: dateRange.start.format('M'),
        startday: dateRange.start.format('D'),
        endyear: dateRange.end.format('YYYY'),
        endmonth: dateRange.end.format('M'),
        endday: dateRange.end.format('D'),
      };
      dispatch(getLeadsListByCampaign(params.id, 2000, daterange, selectedAdAccountId));
    }
  }, [dateRange, dispatch, params, selectedAdAccountId]);

  useEffect(() => {
    return function () {
      // dispatch(setSelectedCompaign(null));
      dispatch(setLeadsList(null));
      dispatch(setHubspotErrorRequest(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (hubspotErrorRequest) {
      toast.error(hubspotErrorRequest, {
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [dispatch, hubspotErrorRequest]);

  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (currentPage > 1) setCurrentPage(1);
    setSearchQuery(e.target.value);
  };

  const pagination = {
    startElement: itemPerPage * (currentPage - 1),
    endElement: itemPerPage * currentPage - 1,
  };

  const paginatedData = useMemo(() => {
    return searchedLeads
      ? searchedLeads.slice(pagination.startElement, pagination.endElement + 1)
      : searchedLeads;
  }, [pagination.endElement, pagination.startElement, searchedLeads]);

  const changeCompanyHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    history.push(`/dash/campaigns/${e.target.value}`);
    dispatch(setLeadsList(null));
  };

  const getHubSpotData = (paginatedData: any) => {
    const token = localStorage.getItem('hubspot-access-code');
    if (paginatedData && paginatedData.length > 0 && token) {
      if (shouldSkip) setTimeout(() => dispatch(getCompaniesFromHubSpot(paginatedData)), 300);
      else dispatch(getCompaniesFromHubSpot(paginatedData));
    }
  };

  const debouncedHandlerSearch = useMemo(
    () => debounce((paginatedData: any) => getHubSpotData(paginatedData), 500),
    []
  );

  useEffect(() => {
    if (paginatedData && paginatedData.length > 0) {
      // const getHubSpotData = () => {
      //   if (shouldSkip) setTimeout(() => dispatch(getCompaniesFromHubSpot(paginatedData)), 300);
      //   else dispatch(getCompaniesFromHubSpot(paginatedData));
      // };
      if (!shouldSkip && isFetchingDataFromHubspot) {
        stoperFunc(true);
      }
      debouncedHandlerSearch(paginatedData);
    }
  }, [dispatch, paginatedData]);

  const handleReloadData = () => {
    if (params && params.id) {
      if (!shouldSkip && isFetchingDataFromHubspot) {
        stoperFunc(true);
      }
      dispatch(setLeadsList(null));
      dispatch(setLeadsInfoFromHubspot(null));
      const daterange = {
        startyear: dateRange.start.format('YYYY'),
        startmonth: dateRange.start.format('M'),
        startday: dateRange.start.format('D'),
        endyear: dateRange.end.format('YYYY'),
        endmonth: dateRange.end.format('M'),
        endday: dateRange.end.format('D'),
      };
      if (selectedAdAccountId)
        dispatch(getLeadsListByCampaign(params.id, 2000, daterange, selectedAdAccountId));
    }
  };

  const withHubspot = useMemo(() => {
    if (hubspotDataList) {
      return paginatedData?.map(item => {
        const findData = hubspotDataList.find(
          i => item['pivotValue~']?.localizedName === i.companyHubspotInfo?.properties.name
        );
        if (findData) return { ...item, hubspot: findData };
        return item;
      });
    }
  }, [hubspotDataList, paginatedData]);

  const tableData = withHubspot ? withHubspot : paginatedData;

  return (
    <div className='pageContent leads'>
      <div className='border-bottom px-3 bg-light pageContent__title'>
        <Breadcrumb className='m-0'>
          <Breadcrumb.Item
            onClick={() => {
              history.push('/dash/campaigns');
            }}>
            {selectedCampaignGroupName || 'Campaigns'}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {selectedCompaign ? selectedCompaign.name : 'Leads'}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {!isFetchingDataFromLinkedIn ? (
        <>
          <div className='p-4'>
            <Form>
              <Row>
                <Col xs={6} sm={6} xxl={3}>
                  <Form.Control
                    className='searchInput'
                    placeholder='Company name'
                    value={searchQuery}
                    onChange={searchHandler}
                  />
                </Col>
                {/* <Col xs={6} sm={4} xxl={3}>
                  <Form.Select aria-label='Is in CRM?'>
                    <option value='linkedin'>Linkedin</option>
                    <option value='hubspot'>Hubspot</option>
                    <option value='both'>Both</option>
                  </Form.Select>
                </Col> */}
                <Col xs={6} sm={4} xxl={3}>
                  {compaignsListForDropDrown && (
                    <Form.Select
                      aria-label='Compaign'
                      value={params.id}
                      onChange={changeCompanyHandler}>
                      {compaignsListForDropDrown?.map(item => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  )}
                </Col>

                <Col>
                  <div className='d-flex justify-content-end align-items-center h-100'>
                    <Button variant='success' onClick={handleReloadData}>
                      <i className='bi bi-arrow-clockwise'></i>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
          <div className='px-4'>
            <Row>
              <Col xs={6}>
                <DateRangePickerComponent dateRange={dateRange} setDateRange={setDateRange} />
              </Col>
              <Col xs={6}>
                {isFetchingDataFromHubspot && (
                  <div className='d-flex justify-content-end align-items-center h-100'>
                    <Spinner animation='grow' variant='warning' className='me-2' size='sm' />{' '}
                    <span className='warningText'>Hubspot data loading...</span>
                  </div>
                )}
              </Col>
            </Row>
          </div>
          <div className='p-4'>
            <Table striped bordered hover className='leads_table'>
              <thead>
                <tr>
                  <th>Company name</th>
                  <th>Impressions</th>
                  <th>Leads</th>
                  <th>Deals</th>
                  {/* <th>Revenue</th> */}
                  {/* <th>Details</th> */}
                </tr>
              </thead>
              <tbody>
                {tableData?.map((item, index) => {
                  return (
                    <tr key={item['pivotValue~']?.id || index} className='leads_table__row'>
                      <td>{item['pivotValue~'] ? item['pivotValue~'].localizedName : '-'}</td>
                      <td>{item.impressions}</td>
                      <td>{item.hubspot?.contacts?.total}</td>
                      <td>{item.hubspot?.deals?.total}</td>
                      {/* <td>{item.revenue}</td> */}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div className='d-flex justify-content-center'>
              {searchedLeads && searchedLeads.length > 0 && (
                <Paginator
                  dataLength={searchedLeads ? searchedLeads.length : null}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  isFetchingDataFromHubspot={isFetchingDataFromHubspot}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <div className='d-flex justify-content-center mt-4'>
          <Spinner animation='border' />
        </div>
      )}
    </div>
  );
};
