import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Modal, Spinner, Table } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { getPaymentHistory, setPaymentHistory } from '../../../redux/slices/payment';
import PaymentHistoryInfo from './PaymentHistoryInfo';

interface IProps {
  isShowPaymentModal: boolean;
  onPaymentOpenHandler: () => void;
  selectedUserId: string | null;
  setSelectedUserId: Dispatch<SetStateAction<string | null>>;
}

export default function PaymentModal({
  isShowPaymentModal,
  onPaymentOpenHandler,
  selectedUserId,
  setSelectedUserId,
}: IProps) {
  const dispatch = useAppDispatch();
  const { paymentHistory, paymentHistoryPending } = useAppSelector(state => state.payment);

  useEffect(() => {
    if (isShowPaymentModal && selectedUserId) {
      dispatch(getPaymentHistory(selectedUserId));
    }
  }, [dispatch, isShowPaymentModal, selectedUserId]);

  useEffect(() => {
    if (isShowPaymentModal === false) {
      dispatch(setPaymentHistory([]));
      setSelectedUserId(null);
    }
  }, [dispatch, isShowPaymentModal, setSelectedUserId]);

  return (
    <Modal size='xl' centered show={isShowPaymentModal} className='settings' scrollable>
      <Modal.Header closeButton onClick={onPaymentOpenHandler}>
        <Modal.Title>Payment History</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {paymentHistoryPending && paymentHistory.length === 0 && (
          <div className='d-flex justify-content-center align-items-center'>
            <Spinner animation='border' variant='primary' />
          </div>
        )}
        {paymentHistory.length > 0 && (
          <Table striped bordered hover size='sm'>
            <thead>
              <tr>
                <th>
                  <div className='p-2'>Billing ID</div>
                </th>
                <th>
                  <div className='p-2'>Date</div>
                </th>
                <th>
                  <div className='p-2'>Amount</div>
                </th>
                {/* <th>
                <div className='p-2'>Subscription Plan</div>
              </th> */}
                <th>
                  <div className='p-2'>Invoice</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paymentHistory.map(item => (
                <PaymentHistoryInfo
                  key={`paymentHistory_${item.id}_${item.invoice}`}
                  invoice={item.invoice}
                  amount={item.amount}
                  id={item.id}
                  created={item.created}
                  currency={item.currency}
                />
              ))}
            </tbody>
          </Table>
        )}
      </Modal.Body>
    </Modal>
  );
}
